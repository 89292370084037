@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@400;500;600;700&display=swap");

/*MEDIA SCREEN VARIABLES*/

/*media screen*/
$screen-desktopWide: 1920px;
$screen-smallDesktop: 1200px;
$screen-desktop: 1440px;
$screen-tablet: 1080px;
$screen-mobileWide: 768px;
$screen-mobileMedium: 640px;
$screen-mobile: 500px;
$screen-mobileSmall: 400px;

/*END MEDIA SCREEN VARIABLES*/

.adTimer {
  position: absolute;
  right: 10;
  top: 10;
  color: white;
  font-weight: bolder;
}

/*MIXIN*/

@mixin respond($media) {
  @if $media==desktopWide {
    @media only screen and (max-width: $screen-desktopWide) {
      @content;
    }
  }
  @if $media==desktop {
    @media only screen and (max-width: $screen-desktop) {
      @content;
    }
  }
  @if $media==smallDesktop {
    @media only screen and (max-width: $screen-smallDesktop) {
      @content;
    }
  }
  @if $media==tablet {
    @media only screen and (max-width: $screen-tablet) {
      @content;
    }
  }
  @if $media==mobileWide {
    @media only screen and (max-width: $screen-mobileWide) {
      @content;
    }
  }
  @if $media==mobileMedium {
    @media only screen and (max-width: $screen-mobileMedium) {
      @content;
    }
  }
  @if $media==mobile {
    @media only screen and (max-width: $screen-mobile) {
      @content;
    }
  }
  @if $media==mobileSmall {
    @media only screen and (max-width: $screen-mobileSmall) {
      @content;
    }
  }
}

@mixin gridRowCol($rowStart, $rowEnd, $colStart, $colEnd) {
  @if $rowStart {
    grid-row-start: $rowStart;
  }
  @if $rowEnd {
    grid-row-end: $rowEnd;
  }
  @if $colStart {
    grid-column-start: $colStart;
  }
  @if $colEnd {
    grid-column-end: $colEnd;
  }
}

/*MIXIN END */

body,
html {
  padding: 0;
  margin: 0;
  background-color: transparent !important;
}

html {
  background: url(bg.jpg) no-repeat center center fixed #c5ebfe;
  background-size: cover;
  @include respond(mobileWide) {
    background: url(mobile-bg.jpg) no-repeat center center fixed #025bd9;
    background-size: cover;
  }
}

* {
  font-family: "Nunito", serif;
  font-weight: 900;
}

ul {
  padding: 0;
  margin: 0;
}

ul li {
  list-style-type: none;
}

* {
  box-sizing: border-box;
}

#humoqRightAdContainerGames {
  visibility: visible;
}

#humoqBottomAdContainerGames {
  visibility: visible;
}

img.playButtons {
  cursor: pointer;
}

.permanentAdClassContainer {
  background-size: 100% !important;
  background-position: center !important;
  position: relative !important;

  .mobilebutton {
    width: 50%;
    height: auto;
    position: absolute;
    padding: 0 10px;
  }
  .mobileandroidbutton {
    bottom: 0px;
    left: 0;
  }
  .mobileiosbutton {
    bottom: 0px;
    right: 0;
  }
  img {
    width: 100%;
  }
}

.humoqRow {
  position: relative;
  display: block;
  width: 100%;
}

.container {
  padding: 0 2rem;
}

.rate {
  float: left;
  height: 46px;
  padding: 0 10px;
}
.rate:not(:checked) > input {
  position: absolute;
  top: -9999px;
}
.rate:not(:checked) > label {
  float: right;
  width: 1em;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  font-size: 30px;
  color: #ccc;
}
.rate:not(:checked) > label:before {
  content: "★ ";
}
.rate > input:checked ~ label {
  color: #ffc700;
}
.rate:not(:checked) > label:hover,
.rate:not(:checked) > label:hover ~ label {
  color: #deb217;
}
.rate > input:checked + label:hover,
.rate > input:checked + label:hover ~ label,
.rate > input:checked ~ label:hover,
.rate > input:checked ~ label:hover ~ label,
.rate > label:hover ~ input:checked ~ label {
  color: #c59b08;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  padding: 30px 5px;
}
.pagination li {
  padding: 0px 5px;
}

.pageLink {
  cursor: pointer;
  text-decoration: none;
  color: #016baa;
}
.pageLink:visited {
  color: #016baa;
}
.pageLink:active {
  color: #016baa;
}
.pageItem {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  border: 1px solid #eaeaea;
  border-radius: 0.5rem;
  cursor: pointer;
}

.pageItemActive {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  border: 1px solid #eaeaea;
  border-radius: 0.5rem;
  cursor: pointer;
  background-color: #016baa;
  color: white;
}
.pageItemActive:visited {
  color: white;
}
.pageItemActive:active {
  color: white;
}

.humoqTitle {
  display: flex;
  font-size: 25px;
  color: #00459c;
  margin: 30px 0 20px 0;
  text-transform: capitalize;
  @include respond(tablet) {
    font-size: 16px;
  }
}

.humoqFixedMenu {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 5;
  transition: all 0.34s ease;
  padding: 8px 0;
  margin-bottom: 35px;
  background-color: #d6f2ff;
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.7);

  @include respond(tablet) {
    padding: 0;
  }

  @include respond(mobileWide) {
    position: relative;
    transition: none;
    background: transparent;
    box-shadow: none;
    margin-top: 15px;
    margin-bottom: 20px;
  }

  .containerNew {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .humoqLeftSection {
    display: flex;
    align-items: center;
    padding-right: 15px;
    .humoqLogo {
      @include respond(tablet) {
        padding: 3px 0;
      }
    }
    .humoqLogo a img {
      @include respond(tablet) {
        width: 168.5px;
        height: auto;
      }
    }
    .hlMobile {
      display: none;
      @include respond(mobileWide) {
        display: flex;
      }
    }
    .hlDesktop {
      display: flex;
      span {
        @media screen and (max-width: 1440px) {
          width: 168px !important;
          height: 48px !important;
        }
        @media screen and (max-width: 1334px) {
          width: 147px !important;
          height: 42px !important;
        }
        @media screen and (max-width: 1180px) {
          width: 140px !important;
          height: 40px !important;
        }
      }
      @include respond(mobileWide) {
        display: none;
      }
    }
  }

  .humoqMenu {
    display: flex;
    justify-content: center;
    padding: 13px 20px;
    margin-left: 10px;
    font-size: 22px;

    @media screen and (max-width: 1730px) {
      font-size: 18px;
    }

    @media screen and (max-width: 1520px) {
      font-size: 16px;
    }

    @media screen and (max-width: 1334px) {
      font-size: 14px;
      padding: 13px 12px;
    }

    @media screen and (max-width: 1120px) {
      display: none;
    }

    .humoqActiveMenu {
      li {
        background-color: #00459c;
        color: #fff;
      }
    }

    a {
      text-decoration: none;
    }

    li {
      color: #00459c;
      text-align: center;
      padding: 11px 22px;
      background: #fff;
      margin: 0 6px;
      border-radius: 10px;
      border-top-left-radius: 0;
      transition: all 0.34s ease;
      box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.25);
      text-transform: capitalize;

      @media screen and (max-width: 1730px) {
        padding: 11px 14px;
      }

      @media screen and (max-width: 1520px) {
        padding: 11px 12px;
        margin: 0 4px;
      }
      @media screen and (max-width: 1334px) {
        padding: 11px 10px;
        margin: 0 4px;
      }
      @media screen and (max-width: 1160px) {
        padding: 10px 12px;
        margin: 0 3px;
      }

      &:hover {
        background: #00459c;
        color: #fff;
      }
    }
  }

  .humoqMobileMenu {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    display: none;
    background-color: rgba(255, 255, 255, 0.35);

    @media screen and (max-width: 1120px) {
      display: block;
    }

    @include respond(mobileWide) {
      display: none;
    }

    .humoqMenu {
      display: flex;
      justify-content: center;
      padding: 10px 10px;
      margin: 0;
      border-radius: 0;
      background: transparent;

      li {
        margin: 0 5px;
      }

      @include respond(mobileWide) {
        justify-content: left;
      }
    }
  }

  .humoqIcon {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    @include respond(desktop) {
      img {
        width: auto;
        height: 100px;
      }
    }
    @media screen and (max-width: 1335px) {
      display: none;
    }
    @media screen and (max-width: 1160px) {
      img {
        width: auto;
        height: 95px;
      }
    }
    @include respond(tablet) {
      img {
        width: auto;
        height: 66px;
      }
    }
    @include respond(mobileWide) {
      display: none;
    }

    span {
      width: 165px !important;
      height: 96px !important;
      @media screen and (max-width: 1445px) {
        width: 175px !important;
        height: 99px !important;
      }
      @media screen and (max-width: 1122px) {
        width: 155px !important;
        height: 87px !important;
      }
    }
  }

  .humoqSearchLoginScope {
    display: flex;
    justify-content: flex-end;
    height: 68px;
    align-items: center;
    padding: 0 10px;
    padding-right: 0;
    .humoqLeaderboardIcon {
      display: flex;
      justify-content: flex-end;
      height: 68px;
      align-items: center;
      padding: 0 15px 0 0;
      margin: 0;
      @include respond(mobileWide) {
        height: auto;
        margin-right: 9px;
        background-color: #fff;
        border-radius: 10px;
        border-top-left-radius: 0;
        align-items: center;
        padding: 10px 8px;
        box-shadow: 0 3px 3px 0 rgb(0 0 0 / 25%);
      }
      a {
        display: inherit;
        img {
          width: 36px;
          @include respond(mobileWide) {
            width: 26px;
          }
        }
      }
    }
    .humoqSearch {
      display: flex;
      justify-content: flex-end;
      height: 68px;
      align-items: center;
      padding: 0;
      margin: 0;

      @include respond(desktop) {
        height: 55px;
        padding-right: 0;
      }

      @media screen and (max-width: 1335px) {
        padding-right: 0;
      }

      @media screen and (max-width: 1160px) {
        padding-right: 0;
      }

      @include respond(tablet) {
        padding-right: 0;
      }

      @include respond(mobileWide) {
        height: auto;
        padding-right: 0;
        background-color: #fff;
        border-radius: 10px;
        border-top-left-radius: 0;
        align-items: center;
        padding: 11px 6px;
        box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.25);
      }

      li {
        margin: 0 5px;
        cursor: pointer;

        .icon-home {
          width: 34.5px;
          height: 32.5px;
          background: url(icon-home.svg);
          background-size: contain;
          background-repeat: no-repeat;

          @include respond(desktop) {
            width: 26.5px;
            height: 26.5px;
          }
        }

        .icon-search {
          width: 34.5px;
          height: 32.5px;
          background: url(icon-search.svg);
          background-size: contain;
          background-repeat: no-repeat;

          @include respond(desktop) {
            width: 26.5px;
            height: 26.5px;
          }

          @include respond(mobileWide) {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
    .humoqLogin {
      position: relative;
      display: flex;
      justify-content: flex-end;
      height: 68px;
      align-items: center;
      padding: 0 0 0 10px;
      @media screen and (max-width: 1335px) {
        padding-right: 0;
      }
      @media screen and (max-width: 768px) {
        padding: 0 0 0 5px;
      }
      .loginButton {
        display: flex;
        align-items: center;
        color: #006baa;
        text-align: center;
        font-size: 20px;
        padding: 10px 12px;
        background: #fff;
        margin: 0 4px;
        border-radius: 10px;
        border-top-left-radius: 0;
        transition: all 0.34s ease;
        box-shadow: 0 3px 3px 0 rgb(0 0 0 / 25%);
        text-transform: capitalize;
        cursor: pointer;

        @media screen and (max-width: 1730px) {
          font-size: 18px;
        }
        @media screen and (max-width: 1520px) {
          font-size: 16px;
        }
        @media screen and (max-width: 1334px) {
          font-size: 14px;
        }
        @media screen and (max-width: 768px) {
          padding: 11px 13px;
        }
        img {
          margin-right: 8px;
          @media screen and (max-width: 1334px) {
            width: 15px;
          }
          @media screen and (max-width: 768px) {
            margin-right: 0;
            width: 16px;
          }
        }
        .loginText {
          display: block;
          @media screen and (max-width: 768px) {
            display: none;
          }
        }
      }
      .humoqLogined {
        display: flex;
        align-items: center;
        color: #fff;
        text-align: center;
        font-size: 20px;
        padding: 10px 12px;
        background: #006baa;
        margin: 0 4px;
        border-radius: 10px;
        border-top-left-radius: 0;
        transition: all 0.34s ease;
        box-shadow: 0 3px 3px 0 rgb(0 0 0 / 25%);
        text-transform: capitalize;
        cursor: pointer;
        @media screen and (max-width: 768px) {
          padding: 13px 14px;
        }
        span {
          @media screen and (max-width: 768px) {
            display: none;
          }
        }
      }
      .humoqLoginedContent {
        position: absolute;
        top: 64px;
        right: 0;
        width: 155px;
        background-color: #fff;
        padding: 20px;
        border: 2px solid #006baa;
        box-shadow: 0px 2.99856px 2.99856px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        border-top-right-radius: 0;
        @media screen and (max-width: 768px) {
          right: 0;
          left: auto;
          width: 155px;
        }
        .humoqLoginedContentItem {
          display: flex;
          margin-bottom: 10px;
          color: #fff;
          text-align: center;
          font-size: 20px;
          z-index: 999;
          @media screen and (max-width: 768px) {
            font-size: 18px;
          }
          a {
            display: flex;
            align-items: center;
            text-decoration: none;
            color: inherit;
          }
          img {
            margin-right: 5px;
          }
          &.Profile {
            color: #006baa;
            img {
              margin-right: 12px;
            }
          }
          &.Logout {
            color: #f34a87;
          }
        }
        .humoqLoginedContentItem:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.mobileHomeMenu {
  display: none;
  @include gridRowCol(1, 3, 1, 2);

  @media screen and (min-width: 540px) and (max-width: 605px) {
    @include gridRowCol(1, 3, 1, 2);
  }

  @media screen and (min-width: 441px) and (max-width: 485px) {
    @include gridRowCol(1, 3, 1, 2);
  }

  @media screen and (max-width: 440px) {
    @include gridRowCol(1, 3, 1, 2);
  }

  .humoqMenu {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include respond(mobileWide) {
      .order5 {
        display: none;
      }
      &.showLast .order5 {
        display: block;
      }
    }

    .buttonDownload {
      li {
        font-size: 9px;
      }
    }
  }

  &.menuDetail {
    @include gridRowCol(6, 8, 1, 2);

    @media screen and (max-width: 768px) {
      @include gridRowCol(12, 14, 1, 2);
    }
    @media screen and (max-width: 724px) {
      @include gridRowCol(10, 12, 1, 2);
    }
    @media screen and (max-width: 591px) {
      @include gridRowCol(10, 12, 1, 2);
    }
    @media screen and (max-width: 473px) {
      @include gridRowCol(10, 12, 1, 2);
    }
  }

  @include respond(mobileWide) {
    display: flex;
  }

  a {
    text-decoration: none;
  }

  li {
    position: relative;
    color: #00459c;
    min-width: 100%;
    text-align: center;
    font-size: 13px;
    padding: 4px 3px;
    background: #fff;
    border-radius: 10px;
    border-top-left-radius: 0;
    transition: all 0.34s ease;
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.25);
    text-transform: capitalize;

    @media screen and (max-width: 400px) {
      font-size: 12px;
      padding: 6px 3px;
    }

    &:hover {
      background: #00459c;
      color: #fff;
    }
  }

  .humoqActiveMenu {
    li {
      background-color: #fee043;
    }
  }
}

.humoqMenu .buttonDownload {
  &.mobile {
    display: none;
    @include respond(mobileWide) {
      display: block;
    }
  }
  @include respond(mobileWide) {
    display: none;
  }
  li {
    color: #fff;
    background: url(icon-download.png) #f34a87 no-repeat center left 18px !important;
    background-size: 20px 19.88px !important;
    padding: 14px 28px 14px 46px;
    cursor: pointer;
    transition: none;
    word-break: break-all;
    font-size: 18px;
    @include respond(desktopWide) {
      background-size: 22px 21.88px !important;
      padding: 14px 28px 14px 46px;
    }
    @media screen and (max-width: 1730px) {
      font-size: 16px;
      padding: 12px 28px 12px 46px;
    }
    @media screen and (max-width: 1520px) {
      font-size: 14px;
      padding: 12.5px 10px 12.5px 36px;
      background: url(icon-download.png) #f34a87 no-repeat center left 9px !important;
      background-size: 18px 17.88px !important;
    }
    @media screen and (max-width: 1334px) {
      background: url(icon-download.png) #f34a87 no-repeat center left 50% !important;
      padding: 21px 8px 21px 40px;
      background-size: 18px 17.88px !important;
      font-size: 14px;
    }
    @media screen and (max-width: 1180px) {
      background: url(icon-download.png) #f34a87 no-repeat center left 50% !important;
      padding: 21px 8px 21px 40px;
      background-size: 18px 17.88px !important;
    }
    @include respond(mobileWide) {
      background-size: 18px 17.88px !important;
      padding: 12.5px 10px 12.5px 36px;
      background-position-x: 7px !important;
      hyphens: auto;
      padding-left: 36px;
      //padding-right: 18px;
      text-align: left;
    }
    @include respond(mobileMedium) {
      padding-left: 33px;
    }
    @include respond(mobile) {
      padding-left: 32px;
    }
    span {
      @media screen and (max-width: 1334px) {
        display: none;
      }
    }
  }
}

.mobileCategoryScope {
  @include respond(mobileWide) {
    width: 100%;
    padding-left: 110px;
  }
  .humoqTitle {
    @include respond(mobileWide) {
      margin-top: 20px;
      margin-bottom: 10px;
    }
  }
}

.gameDetails {
  margin-top: 40px;
  .contentScope {
    background: #f3fbff;
    padding: 50px;
    border-radius: 25px;
    @include respond(mobileWide) {
      padding: 25px;
      border-radius: 20px;
      word-break: break-word;
    }
  }
  h1 {
    font-weight: 900;
    color: #006baa;
    font-size: 20px;
    line-height: 140%;
    margin: 0;
    padding: 0;
    padding-bottom: 10px;
    @include respond(mobileWide) {
      font-size: 18px;
    }
  }
  h2 {
    font-weight: 900;
    color: #006baa;
    font-size: 18px;
    margin: 0;
    padding: 0;
    padding-top: 30px;
    line-height: 140%;
    @include respond(mobileWide) {
      font-size: 17px;
    }
  }
  h3 {
    font-weight: 900;
    color: #006baa;
    font-size: 18px;
    margin: 0;
    padding: 0;
    padding-top: 30px;
    line-height: 140%;
    @include respond(mobileWide) {
      font-size: 17px;
    }
  }
  p {
    font-size: 16px;
    font-weight: 400;
    color: #006baa;
    line-height: 140%;
    padding: 10px 0;
    margin: 0;
    a {
      color: #006baa;
      text-decoration: none;
    }
    @include respond(mobileWide) {
      font-size: 15px;
    }
  }
  ul {
    padding-left: 40px;
    li {
      list-style-type: disc;
      color: #006baa;
      a {
        text-decoration: none;
        color: #006baa;
        font-weight: 900;
        font-size: 20px;
        @include respond(mobileWide) {
          font-size: 16px;
        }
      }
    }
  }
  a.tag {
    font-size: 18px;
    font-weight: 800;
    padding: 5px 16px;
    border: 1px solid #006baa;
    margin-right: 10px;
    margin-top: 10px;
    border-radius: 20px;
    text-decoration: none;
    color: #006baa;
    display: inline-block;
    @include respond(mobileWide) {
      font-size: 14px;
    }
  }
}

.profileDetails {
  margin-top: 40px;
  .profileScope {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #f3fbff;
    padding: 21px 50px;
    border-radius: 17px;
    max-width: 1222px;
    width: 100%;
    margin: 0 auto;
    @include respond(mobileWide) {
      padding: 25px;
      border-radius: 20px;
      word-break: break-word;
    }
    @include respond(mobileMedium) {
      justify-content: center;
      flex-direction: column;
    }
    .profileScopeLeft {
      display: flex;
      align-items: center;
      @include respond(mobileMedium) {
        margin-bottom: 20px;
      }
      .profileAvatar {
        width: 124px;
        height: 124px;
        border-radius: 50%;
        overflow: hidden;
        img {
          width: 100%;
        }
        @media screen and (max-width: 1120px) {
          width: 100px;
          height: 100px;
        }
        @include respond(mobileWide) {
          width: 72px;
          height: 72px;
        }
      }
      .profileInfo {
        display: flex;
        flex-direction: column;
        padding-left: 26px;
        @media screen and (max-width: 1120px) {
          padding-left: 18px;
        }
        .profileInfoName {
          font-weight: 900;
          font-size: 32px;
          line-height: 44px;
          color: #006baa;
          @media screen and (max-width: 1120px) {
            font-size: 24px;
            line-height: 36px;
          }
          @include respond(mobileWide) {
            font-size: 22px;
            line-height: 26px;
          }
        }
        .profileInfoMail {
          font-weight: 600;
          font-size: 18px;
          line-height: 25px;
          color: #9cbacc;
          @media screen and (max-width: 1120px) {
            font-size: 16px;
            line-height: 23px;
          }
          @include respond(mobileWide) {
            font-size: 14px;
            line-height: 16px;
          }
        }
      }
    }
    .profileScopeRight {
      display: flex;
      align-items: center;
      .profileScoreScope {
        padding-right: 45px;
        border-right: 1px solid #9cbacc;
        @media screen and (max-width: 1120px) {
          padding-right: 25px;
        }
        .profileTotalScoreTitle {
          font-weight: 600;
          font-size: 18px;
          line-height: 25px;
          text-align: center;
          color: #006baa;
          @media screen and (max-width: 1120px) {
            font-size: 16px;
            line-height: 23px;
          }
          @include respond(mobileWide) {
            font-size: 14px;
            line-height: 16px;
          }
        }
        .profileTotalScore {
          font-weight: 800;
          font-size: 32px;
          line-height: 44px;
          text-align: center;
          color: #006baa;
          img {
            width: 30px;
            @media screen and (max-width: 1120px) {
              width: 24px;
            }
          }
          @media screen and (max-width: 1120px) {
            font-size: 26px;
            line-height: 38px;
          }
          @include respond(mobileWide) {
            font-size: 24px;
            line-height: 30px;
          }
        }
      }
      .profileSocialMediaScope {
        display: flex;
        align-items: center;
        flex-direction: column;
        padding-left: 45px;
        @media screen and (max-width: 1120px) {
          padding-left: 25px;
        }
        .profileSocialMediaTitle {
          font-weight: 600;
          font-size: 18px;
          line-height: 25px;
          text-align: center;
          color: #006baa;
          padding-bottom: 4px;
          @include respond(mobileWide) {
            font-size: 14px;
            line-height: 16px;
          }
        }
        .profileSocialMedia {
          img {
            width: 29px;
            margin-right: 18px;
            @media screen and (max-width: 1120px) {
              width: 24px;
              margin-right: 10px;
            }
            @include respond(mobileWide) {
              width: 22px;
              margin-right: 11px;
            }
          }
        }
      }
    }
    .profileEdit {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      right: 0;
      top: 0;
      width: 36px;
      height: 36px;
      border-radius: 0 17px 0 17px;
      background-color: #006baa;
      cursor: pointer;
      img {
        width: 16px;
      }
    }
  }
  .dataContainer {
    max-width: 1222px;
    width: 100%;
    margin: 0 auto;
    .dataTitle {
      font-size: 25px !important;
    }
  }
}

.humoqBasicWrapper {
  display: inline-block;
  position: relative;
  width: 100%;

  .humoqColBasic {
    display: inline-block;
    position: relative;
    width: 100px;
    height: 100px;
    margin-right: 15px;
    margin-bottom: 15px;
    overflow: hidden;
    border-radius: 10px;
    transition: all 0.34s ease;
    position: relative;
    background: #44c8fd;
    box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.2);

    @include respond(mobileWide) {
      width: 80px;
      height: 80px;
      margin-right: 13px;
      margin-bottom: 13px;
    }

    img {
      transition: all 0.34s ease;
      width: 100%;
      object-fit: cover;
    }

    .humoqText {
      color: #fff;
      font-size: 12px;
      line-height: 120%;
      padding: 10px 5px;
      position: absolute;
      width: 100%;
      height: auto;
      bottom: -100px;
      z-index: 5;
      background: rgba(0, 0, 0, 0.5);
      transition: all 0.34s ease;

      @include respond(tablet) {
        font-size: 10px;
      }
    }

    &:hover {
      transform: scale(1.1);
      z-index: 2;

      img {
        transform: scale(1.1);
      }

      .humoqText {
        bottom: 0;
      }
    }
  }
}

/*NEW*/
.containerNew {
  position: relative;
  width: 100%;
  max-width: 1854px;
  padding: 0 10px;
  margin: 0 auto;
  @media screen and (max-width: 1920px) {
    max-width: 1654px;
  }
  @media screen and (max-width: 1730px) {
    max-width: 1454px;
  }
  @media screen and (max-width: 1520px) {
    max-width: 1320px;
  }
  @media screen and (max-width: 1334px) {
    max-width: 100%;
  }
  @media screen and (max-width: 1120px) {
    max-width: 894px;
  }
}

.humoqWrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(95px, 1fr));
  grid-gap: 20px;
  grid-auto-rows: 97px;
  @media screen and (max-width: 1520px) {
    grid-template-columns: repeat(auto-fill, minmax(90px, 1fr));
    grid-auto-rows: 90px;
  }

  @media screen and (max-width: 1120px) {
    grid-template-columns: repeat(auto-fill, minmax(79px, 1fr));
    grid-auto-rows: 81px;
  }

  @media screen and (max-width: 894px) {
    grid-template-columns: repeat(auto-fill, minmax(72px, 1fr));
    grid-auto-rows: 72px;
  }

  @media screen and (max-width: 850px) {
    grid-template-columns: repeat(auto-fill, minmax(67px, 1fr));
    grid-auto-rows: 67px;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(102px, 1fr));
    grid-auto-rows: 102px;
  }

  @media screen and (max-width: 680px) {
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    grid-auto-rows: 100px;
  }

  @media screen and (max-width: 605px) {
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    grid-auto-rows: 100px;
  }

  @media screen and (max-width: 540px) {
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    grid-auto-rows: 100px;
  }

  @media screen and (max-width: 485px) {
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    grid-auto-rows: 100px;
  }

  @media screen and (max-width: 440px) {
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    grid-auto-rows: 100px;
  }

  @media screen and (max-width: 400px) {
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    grid-auto-rows: 100px;
  }

  @media screen and (max-width: 370px) {
    grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
    grid-auto-rows: 100px;
  }

  @media screen and (max-width: 340px) {
    grid-auto-rows: 90px;
  }

  .hqCol {
    display: flex;
    justify-content: center;
    overflow: hidden;
    border-radius: 10px;
    transition: all 0.34s ease;
    position: relative;
    background: #44c8fd;
    cursor: pointer;
    box-shadow: 0 0 4px 2px rgb(0 0 0 / 20%);
    img {
      transition: all 0.34s ease;
    }

    .humoqText {
      color: #fff;
      font-size: 12px;
      line-height: 120%;
      padding: 10px 5px;
      position: absolute;
      width: 100%;
      height: auto;
      bottom: -100px;
      z-index: 5;
      background: rgba(0, 0, 0, 0.5);
      transition: all 0.34s ease;
      @include respond(tablet) {
        font-size: 10px;
        line-height: 120%;
      }
    }

    &:hover {
      transform: scale(1.06);
      z-index: 2;
      border: 2px solid #000000;

      img {
        transform: scale(1.1);
      }

      .humoqText {
        bottom: 0;
      }
    }

    &.col-0 {
      @include gridRowCol(1, 4, 3, 6);
      font-size: 16px;
      @include respond(desktop) {
        @include gridRowCol(1, 4, 1, 4);
      }
      @include respond(tablet) {
        @include gridRowCol(1, 3, 2, 4);
      }
      @include respond(mobileWide) {
        @include gridRowCol(1, 3, 2, 4);
      }
    }

    &.col-1 {
      @include gridRowCol(1, 3, 8, 10);
      font-size: 22px;
      @include respond(desktop) {
        @include gridRowCol(false, false, 5, 7);
      }
      @include respond(tablet) {
        @include gridRowCol(1, 3, 5, 7);
      }
      @include respond(mobileWide) {
        @include gridRowCol(3, 5, 3, 5);
      }
      @include respond(mobile) {
        @include gridRowCol(4, 6, 2, 4);
      }
    }

    &.col-2 {
      @include gridRowCol(1, 3, 10, 12);
      @include respond(desktopWide) {
        @include gridRowCol(false, false, 10, 12);
      }
      @include respond(desktop) {
        @include gridRowCol(false, false, 8, 10);
      }
      @include respond(smallDesktop) {
        @include gridRowCol(false, false, 8, 10);
      }
      @include respond(tablet) {
        @include gridRowCol(4, 6, 1, 3);
      }
      @include respond(mobileWide) {
        @include gridRowCol(6, 8, 1, 3);
      }
    }

    &.col-3 {
      @include gridRowCol(1, 4, 12, 15);
      @include respond(desktopWide) {
        @include gridRowCol(4, 6, 1, 3);
      }
      @include respond(tablet) {
        @include gridRowCol(3, 5, 4, 6);
      }
      @include respond(mobileWide) {
        @include gridRowCol(6, 8, 3, 5);
      }
      @include respond(mobile) {
        @include gridRowCol(8, 10, 2, 4);
      }
    }

    &.col-4 {
      @include gridRowCol(4, 6, 1, 3);
      @include respond(desktopWide) {
        @include gridRowCol(4, 6, 9, 11);
      }
      @include respond(desktop) {
        @include gridRowCol(4, 6, 3, 5);
      }
      @include respond(tablet) {
        @include gridRowCol(5, 7, 4, 6);
      }
      @include respond(mobileWide) {
        @include gridRowCol(9, 11, 2, 4);
      }
      @include respond(mobile) {
        @include gridRowCol(11, 13, 2, 4);
      }
    }

    &.col-5 {
      @include gridRowCol(4, 7, 6, 9);
      @include respond(desktop) {
        @include gridRowCol(4, 7, 5, 8);
      }
      @include respond(tablet) {
        @include gridRowCol(7, 9, 2, 4);
      }
      @include respond(mobileWide) {
        @include gridRowCol(12, 14, 1, 3);
      }
      @include respond(mobile) {
        @include gridRowCol(13, 15, 1, 3);
      }
    }

    &.col-6 {
      @include gridRowCol(4, 6, 11, 13);
      @include respond(desktop) {
        @include gridRowCol(4, 6, 8, 10);
      }

      @include respond(tablet) {
        @include gridRowCol(8, 10, 5, 7);
      }
      @include respond(mobileWide) {
        @include gridRowCol(14, 16, 3, 5);
      }
      @include respond(mobile) {
        @include gridRowCol(16, 18, 2, 4);
      }
    }

    &.col-7 {
      @include gridRowCol(6, 8, 11, 13);
      @include respond(desktop) {
        @include gridRowCol(6, 8, 8, 10);
      }
      @include respond(tablet) {
        @include gridRowCol(9, 11, 1, 3);
      }
      @include respond(mobileWide) {
        @include gridRowCol(inherit, inherit, inherit, inherit);
      }
    }

    &.col-8 {
      @include gridRowCol(5, 7, 15, 17);
      @include respond(desktopWide) {
        @include gridRowCol(9, 11, 11, 13);
      }
      @include respond(desktop) {
        @include gridRowCol(false, false, 4, 6);
      }
      @include respond(tablet) {
        @include gridRowCol(11, 13, 2, 4);
      }
      @include respond(mobileWide) {
        @include gridRowCol(inherit, inherit, inherit, inherit);
      }
    }

    &.col-10 {
      @include gridRowCol(6, 9, 2, 5);
      @include respond(tablet) {
        @include gridRowCol(11, 13, 4, 6);
      }
      @include respond(mobileWide) {
        @include gridRowCol(inherit, inherit, inherit, inherit);
      }
    }

    &.col-9 {
      @include gridRowCol(8, 10, 7, 9);
      @include respond(desktop) {
        @include gridRowCol(8, 10, 6, 8);
      }
      @include respond(tablet) {
        @include gridRowCol(14, 16, 1, 3);
      }
      @include respond(mobileWide) {
        @include gridRowCol(inherit, inherit, inherit, inherit);
      }
    }

    &.col-11 {
      @include gridRowCol(8, 10, 9, 11);
      @include respond(desktop) {
        @include gridRowCol(8, 10, 8, 10);
      }
      @include respond(tablet) {
        @include gridRowCol(14, 16, 5, 7);
      }
      @include respond(mobileWide) {
        @include gridRowCol(inherit, inherit, inherit, inherit);
      }
    }

    &.col-12 {
      @include gridRowCol(9, 12, 13, 16);
      @include respond(desktopWide) {
        @include gridRowCol(12, 14, 10, 12);
      }
      @include respond(desktop) {
        @include gridRowCol(14, 17, 1, 4);
      }
      @include respond(tablet) {
        @include gridRowCol(inherit, inherit, inherit, inherit);
      }
    }

    &.col-13 {
      @include gridRowCol(11, 13, 1, 3);
      @include respond(tablet) {
        @include gridRowCol(inherit, inherit, inherit, inherit);
      }
    }

    &.col-14 {
      @include gridRowCol(11, 13, 3, 5);
      @include respond(tablet) {
        @include gridRowCol(inherit, inherit, inherit, inherit);
      }
    }

    &.col-15 {
      @include gridRowCol(11, 14, 6, 9);
      @include respond(tablet) {
        @include gridRowCol(inherit, inherit, inherit, inherit);
      }
    }
  }

  .humoqColDetails {
    display: flex;
    justify-content: center;
    overflow: hidden;
    border-radius: 10px;
    transition: all 0.34s ease;
    position: relative;
    background: #44c8fd;
    box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.2);

    img {
      transition: all 0.34s ease;
    }

    .humoqText {
      color: #fff;
      font-size: 12px;
      line-height: 120%;
      padding: 10px 5px;
      position: absolute;
      width: 100%;
      height: auto;
      bottom: -100px;
      z-index: 5;
      background: rgba(0, 0, 0, 0.5);
      transition: all 0.34s ease;

      @include respond(tablet) {
        font-size: 10px;
        line-height: 120%;
      }
    }

    &:hover {
      transform: scale(1.1);
      z-index: 2;
      border: 2px solid #000000;

      img {
        transform: scale(1.1);
      }

      .humoqText {
        bottom: 0;
      }
    }
  }

  .humoqColCat {
    display: flex;
    justify-content: center;
    overflow: hidden;
    border-radius: 10px;
    transition: all 0.34s ease;
    position: relative;
    background: #44c8fd;
    box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.2);

    img {
      transition: all 0.34s ease;
    }

    .humoqText {
      color: #fff;
      font-size: 12px;
      line-height: 120%;
      padding: 10px 5px;
      position: absolute;
      width: 100%;
      height: auto;
      bottom: -100px;
      z-index: 5;
      background: rgba(0, 0, 0, 0.5);
      transition: all 0.34s ease;

      @include respond(tablet) {
        font-size: 10px;
        line-height: 120%;
      }
    }

    &:hover {
      transform: scale(1.1);
      z-index: 2;

      img {
        transform: scale(1.1);
      }

      .humoqText {
        bottom: 0;
      }
    }
  }

  &.colOneItemEffect .humoqColCat {
    &.hqCatCol-0 {
      @include respond(mobileWide) {
        @include gridRowCol(1, 3, 2, 4);
      }
    }
  }
  .humoqColIframeBottom {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(105px, 1fr));
    grid-gap: 15px;
    grid-auto-rows: 105px;
    overflow: hidden;
    overflow-y: scroll;
    @media screen and (max-width: 725px) {
      grid-auto-rows: 128px;
    }

    @media screen and (max-width: 680px) {
      grid-auto-rows: 110px;
    }

    @media screen and (max-width: 605px) {
      grid-auto-rows: 128px;
    }

    @media screen and (max-width: 540px) {
      grid-auto-rows: 110px;
    }

    @media screen and (max-width: 485px) {
      grid-auto-rows: 144px;
    }

    @media screen and (max-width: 440px) {
      grid-auto-rows: 125px;
    }

    @media screen and (max-width: 400px) {
      grid-auto-rows: 115px;
    }

    @media screen and (max-width: 370px) {
      grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
      grid-auto-rows: 100px;
    }

    @media screen and (max-width: 340px) {
      grid-auto-rows: 90px;
    }
  }
  .humoqColIframeRight {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(105px, 1fr));
    grid-gap: 15px;
    grid-auto-rows: 105px;
    overflow: hidden;
    overflow-y: scroll;
    @media screen and (max-width: 725px) {
      grid-auto-rows: 128px;
    }

    @media screen and (max-width: 680px) {
      grid-auto-rows: 110px;
    }

    @media screen and (max-width: 605px) {
      grid-auto-rows: 128px;
    }

    @media screen and (max-width: 540px) {
      grid-auto-rows: 110px;
    }

    @media screen and (max-width: 485px) {
      grid-auto-rows: 144px;
    }

    @media screen and (max-width: 440px) {
      grid-auto-rows: 125px;
    }

    @media screen and (max-width: 400px) {
      grid-auto-rows: 115px;
    }

    @media screen and (max-width: 370px) {
      grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
      grid-auto-rows: 100px;
    }

    @media screen and (max-width: 340px) {
      grid-auto-rows: 90px;
    }
  }

  .humoqCol-Iframe-Right {
    @include gridRowCol(1, 7, 14, 17);
    @include respond(desktopWide) {
      @include gridRowCol(1, 7, 13, 16);
    }
    @media screen and (max-width: 1824px) {
      @include gridRowCol(1, 7, 13, 16);
    }
    @media screen and (max-width: 1684px) {
      @include gridRowCol(1, 6, 11, 14);
    }
    @media screen and (max-width: 1564px) {
      @include gridRowCol(1, 6, 10, 13);
    }
    @media screen and (max-width: 1444px) {
      @include gridRowCol(1, 6, 9, 12);
    }
    @media screen and (max-width: 1334px) {
      @include gridRowCol(1, 6, 9, 11);
    }
    @media screen and (max-width: 1284px) {
      @include gridRowCol(1, 6, 8, 10);
    }
    @media screen and (max-width: 1084px) {
      @include gridRowCol(1, 6, 8, 9);
    }
    @media screen and (max-width: 964px) {
      display: none !important;
    }
    @include respond(mobileWide) {
      border-radius: 15px;
    }
  }

  .humoqCol-Iframe-Bottom {
    @include gridRowCol(7, 10, 4, 13);
    @include respond(desktopWide) {
      @include gridRowCol(7, 10, 4, 13);
    }
    @media screen and (max-width: 1824px) {
      @include gridRowCol(7, 10, 4, 13);
    }
    @media screen and (max-width: 1684px) {
      @include gridRowCol(6, 9, 3, 11);
    }
    @media screen and (max-width: 1564px) {
      @include gridRowCol(6, 9, 2, 10);
    }
    @media screen and (max-width: 1444px) {
      @include gridRowCol(6, 9, 1, 9);
    }
    @media screen and (max-width: 1334px) {
      @include gridRowCol(6, 9, 1, 9);
    }
    @media screen and (max-width: 1284px) {
      @include gridRowCol(6, 9, 1, 9);
    }
    @media screen and (max-width: 1084px) {
      @include gridRowCol(6, 9, 1, 9);
    }
    @media screen and (max-width: 964px) {
      display: none !important;
    }
    @include respond(mobileWide) {
      border-radius: 15px;
    }
  }
  .humoqColDetailsIframe {
    display: flex;
    justify-content: center;
    overflow: hidden;
    position: relative;
    background: #44c8fd;
    padding-bottom: 50px;
    box-shadow: 0px 0px 4px 2px rgb(0 0 0 / 20%);

    @include respond(mobileWide) {
      border-radius: 15px;
    }

    iframe.fullscreen {
      position: fixed;
      top: 0;
      left: 0;
      width: 100% !important;
      height: 100% !important;
      z-index: 9 !important;
    }

    .iframeClose {
      position: fixed;
      left: 5px;
      top: 5px;
      background: url(icon-previous.png) no-repeat center center;
      background-size: cover;
      width: 39px;
      height: 39px;
      display: none;
      z-index: 10;
      cursor: pointer;
      &.active {
        display: block;
      }
    }

    .iframeInfo {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 50px;
      background-color: #fff;
      display: flex;
      justify-content: space-between;
      align-items: center;
      overflow: hidden;
      z-index: 4;

      @include respond(mobile) {
        height: 58px;
      }

      .ifiLeft {
        display: flex;
        padding-left: 10px;
        justify-content: space-between;
        align-items: center;
        .ifiGameImage {
          width: 30px;
          height: 30px;
          overflow: hidden;
          border-radius: 10px;
          transition: all 0.34s ease;
          position: relative;
          background: #0279dc;
          box-shadow: 0px 0px 4px 2px rgb(0 0 0 / 20%);
          display: flex;
        }
        .ifiGameText {
          display: flex;
          padding-left: 5px;
          font-size: 14px;
          width: 300px;
          @include respond(mobileWide) {
            width: 220px;
          }
        }
      }
      .ifiRight {
        display: flex;
        justify-content: space-between;
        padding-right: 10px;
        padding-top: 4px;

        .ifiFullScreen {
          display: flex;
          margin-left: 20px;
          margin-top: 4px;
          cursor: pointer;
          @include respond(mobileWide) {
            margin-left: 0;
          }
        }
        .ifiSocial {
          display: flex;
          margin: 0 2px;
          cursor: pointer;
          @include respond(mobileWide) {
            display: none;
          }
        }
        .ifiScreenRecord {
          display: flex;
          margin: 0 3px 0 0;
          font-size: 14px;
          cursor: pointer;
          background-image: url(icon-screen-record.png);
          background-color: #f9005a;
          background-repeat: no-repeat;
          color: #fff;
          background-size: 31px auto;
          background-position: center right 10px;
          padding-right: 45px;
          padding-left: 10px;
          border-radius: 13px;
          align-items: center;
          height: 38px;
          @media screen and (max-width: 1120px) {
            font-size: 12px;
          }
          @include respond(mobileWide) {
            display: none;
          }
        }
        .ifiStopRecord {
          display: flex;
          margin: 0 15px 0 0;
          cursor: pointer;
          background-image: url(icon-stop-record.png);
          background-color: #f9005a;
          background-repeat: no-repeat;
          color: #fff;
          background-size: 29px auto;
          background-position: center right 10px;
          padding-right: 48px;
          padding-left: 10px;
          border-radius: 13px;
          align-items: center;
          height: 38px;
          @include respond(mobileWide) {
            display: none;
          }
        }
      }
    }

    @include respond(desktop) {
      > iframe {
        width: 100%;
        height: auto;
      }
    }
    &.humoqCol-Iframe {
      @include gridRowCol(1, 10, 2, 14);
      @include respond(desktopWide) {
        @include gridRowCol(1, 8, 1, 12);
      }
      @media screen and (max-width: 1824px) {
        @include gridRowCol(1, 8, 1, 12);
      }
      @media screen and (max-width: 1730px) {
        @include gridRowCol(1, 7, 1, 10);
      }
      @media screen and (max-width: 1564px) {
        @include gridRowCol(1, 7, 1, 10);
      }
      @media screen and (max-width: 1444px) {
        @include gridRowCol(1, 7, 1, 10);
      }
      @media screen and (max-width: 1334px) {
        @include gridRowCol(1, 7, 1, 10);
      }
      @media screen and (max-width: 1284px) {
        @include gridRowCol(1, 7, 1, 10);
      }
      @media screen and (max-width: 1120px) {
        @include gridRowCol(1, 7, 1, 10);
      }
      @media screen and (max-width: 964px) {
        @include gridRowCol(1, 7, 1, 10);
      }
      @media screen and (max-width: 844px) {
        @include gridRowCol(1, 8, 1, 10);
      }
      @media screen and (max-width: 768px) {
        @include gridRowCol(1, 6, 1, 7);
      }
      @media screen and (max-width: 724px) {
        @include gridRowCol(1, 5, 1, 6);
      }
      @media screen and (max-width: 591px) {
        @include gridRowCol(1, 5, 1, 5);
      }
      @media screen and (max-width: 473px) {
        @include gridRowCol(1, 4, 1, 4);
      }
    }

    .mobilePlayButton {
      position: absolute;
      left: 0;
      width: 100%;
      height: 100%;
      display: none;
      align-items: center;
      background-size: cover;
      background-position: center;
      cursor: pointer;
      z-index: 2;
      &.false {
        display: none;
      }
      @include respond(mobileWide) {
        display: flex;
      }
      .bgOpacity {
        z-index: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-color: rgba(0, 0, 0, 0.4);
      }
      .mobilePlayIcon {
        z-index: 1;
        display: inline-block;
        width: 100%;
        text-align: center;
      }
      .mobilePlay {
        z-index: 1;
        display: inline-block;
        width: 100%;
        text-align: center;
        color: #fff;
        font-size: 18px;
      }
    }
  }

  .mobileSocial {
    display: none;
    @include respond(mobileWide) {
      display: flex;
      justify-content: center;
      overflow: hidden;
      position: relative;
      flex-wrap: wrap;
      @include gridRowCol(12, 13, 2, 4);

      @media screen and (max-width: 724px) {
        @include gridRowCol(10, 11, 2, 4);
      }
      @media screen and (max-width: 591px) {
        @include gridRowCol(10, 11, 2, 4);
      }
      @media screen and (max-width: 473px) {
        @include gridRowCol(10, 11, 2, 4);
      }

      .ifiHead {
        display: inline-block;
        width: 100%;
        color: #005ed0;
        font-size: 12px;
        text-align: center;
        padding-top: 20px;
      }
      .ifiSocial {
        display: inline-block;
        padding: 3px 3px 0 3px;
        svg path {
          fill: #fff;
        }
      }
    }
  }
}

// Drawer
.DrawerOverlay {
  position: fixed;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 4;
  background-color: #00459c70;
  transition: all 0.34s ease-in-out;
}

.DrawerHolder {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 6;
  background-color: #c3ebfd;
  box-shadow: 0 10px 20px 0 #0000008a;
  width: 0;
  height: 100%;
  transition: all 0.1s ease-in-out;

  .drawerCloseIcon {
    width: 100%;
    display: none;
    justify-content: flex-end;
    margin-bottom: 15px;

    img {
      cursor: pointer;
    }

    @include respond(tablet) {
      display: flex;
    }
  }

  &.DrawerHolderActive {
    width: 955px;
  }

  @include respond(tablet) {
    &.DrawerHolderActive {
      width: 100%;
    }
  }

  &.DrawerHolderDeActive {
    width: 0;
    visibility: hidden;

    .footer {
      .footerImage {
        opacity: 0;
      }
    }
  }

  .DrawerRightContent {
    padding: 30px 100px;

    @include respond(tablet) {
      padding: 30px;
    }
  }

  .DrawerContentHolder {
    position: relative;
    height: 100%;

    @include respond(mobileWide) {
      overflow: auto;
    }

    .DrawerCloseHolder {
      position: absolute;
      left: -23px;
      top: 32px;
      cursor: pointer;
      background: url(right-icon.svg) #fff;
      padding: 30px;
      border-radius: 100%;
      width: 34.5px;
      height: 32.5px;
      background-position: center;
      background-repeat: no-repeat;
      box-shadow: 0 3px 3px 0 rgb(0 0 0 / 25%);

      @include respond(tablet) {
        display: none;
      }
    }
  }
}

// Search Container
.footer {
  .footerImage {
    background-image: url(follow.png);
    height: 255px;
    width: 308px;
    position: absolute;
    bottom: 0;
    right: 0;
    transition: all 0.34s ease;
    opacity: 1;
  }

  @include respond(tablet) {
    display: none;
  }
}

.dataContainer {
  margin-top: 67px;

  .dataTitle {
    color: #006baa;
    font-size: 20px;
    margin: 50px 0 10px 0;
    @include respond(tablet) {
      font-size: 16px;
    }
  }

  .dataHolder {
    display: flex;
    flex-wrap: wrap;
    margin-top: 15px;

    .dataCard {
      width: 100px;
      height: 100px;
      border-radius: 10px;
      overflow: hidden;
      cursor: pointer;
      margin-right: 10px;
      margin-top: 10px;

      img {
        object-fit: cover;
        height: 100%;
        width: 100%;
      }
    }
  }

  &.jumpto {
    margin-top: 15px;

    a {
      text-decoration: none;
      color: #8ac9ff;

      li {
        display: inline-block;
        padding: 5px;
        margin: 0 5px 5px 0;
        background: #fff;
        color: #00459c;
        border-radius: 8px;
        border-top-left-radius: 0px;
        transition: all 0.34s ease;
        font-size: 14px;
        box-shadow: 0 2px 1px 0 rgb(0 0 0 / 15%);
        text-transform: capitalize;

        @include respond(mobileWide) {
          font-size: 11px;
        }

        &:hover {
          background-color: #00459c;
          color: #fff;
        }
      }
    }

    .dataTitle {
      margin-top: 10px;
      color: #006baa;
    }
  }
}

.searchContainer {
  display: flex;
  position: relative;

  span {
    position: absolute !important;
    height: 30px;
    width: 30px;
    background-image: url(search.svg);
    background-size: 100%;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
  }

  .searchInput {
    width: 100%;
    height: 68px;
    font-size: 22px;
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.25);
    background-color: #fff;
    border: unset;
    outline: unset;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    padding-left: 15px;
    color: #00459c;

    &::placeholder {
      color: #00459c;
      font-size: 22px;
      vertical-align: middle;
    }
  }
}

/*-----*/

.humoqBreadCrumb {
  display: block;
  position: relative;
  margin-bottom: 15px;
  margin-top: -20px;
  &.detail {
    padding-left: 115px;
    @include respond(desktopWide) {
      padding-left: 120px;
    }
    @media screen and (max-width: 1684px) {
      padding-left: 120px;
    }
    @media screen and (max-width: 1344px) {
      padding-left: 110px;
    }
    @media screen and (max-width: 1284px) {
      padding-left: 0;
    }
    @media screen and (max-width: 960px) {
      padding-left: 0;
    }
  }
  @include respond(mobileWide) {
    margin-top: 0;
  }
  ol {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 12px;
    color: #67afce;
    @include respond(mobileWide) {
      font-size: 11px;
    }
    li {
      display: inline-block;
      text-transform: capitalize;
    }
    .slash:after {
      content: "/";
      display: inline-block;
      padding: 0 3px;
    }
    a {
      text-decoration: none;
      color: #67afce;
    }
  }
}

.mainFooter {
  background: #f3fcff;
  margin-top: 70px;
  .containerNew {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .footerLogo {
    display: flex;
    order: 1;
    flex: 0;
    @include respond(tablet) {
      margin-bottom: 10px;
      img {
        width: 142px;
        height: auto;
      }
    }
  }
  .footerSocial {
    display: flex;
    order: 3;
    flex: 0;
    align-items: center;
    @include respond(mobileMedium) {
      flex: 3;
      justify-content: flex-end;
    }
    li {
      display: flex;
      padding: 0 8px;
      &:last-child {
        padding-right: 0;
      }
      a {
        display: flex;
      }
      @include respond(tablet) {
        img {
          width: auto;
          height: 24px;
        }
      }
      @include respond(mobileWide) {
        padding: 0 5px;
      }
    }
  }
  .footerUrl {
    display: flex;
    order: 2;
    flex: 3;
    justify-content: flex-end;
    padding-right: 40px;
    font-weight: 900;
    font-size: 18px;
    @include respond(tablet) {
      padding-right: 20px;
      font-size: 14px;
      margin-bottom: 20px;
    }
    @include respond(mobileWide) {
      font-size: 12px;
      padding-right: 5px;
    }
    @include respond(mobileMedium) {
      display: none;
    }
    li {
      display: flex;
      padding: 0 10px;
      a {
        color: #00459c;
      }
      @include respond(mobileWide) {
        padding: 0 5px;
      }
    }
  }
  .footerDesktop {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    @media screen and (max-width: 800px) {
      flex-direction: column;
    }
  }
  .footerMobile {
    display: none;
    position: relative;
    width: 100%;
    margin-top: 15px;
    .footerUrl {
      justify-content: center;
      display: flex;
      padding-right: 0;
      @media screen and (max-width: 360px) {
        font-size: 10px;
      }
      li {
        padding: 0 5px;
        @media screen and (max-width: 360px) {
          padding: 0 4px;
        }
      }
    }
    @include respond(mobileMedium) {
      display: inline-block;
    }
  }
  &.de,
  &.fr,
  &.it,
  &.pl,
  &.cs,
  &.nl {
    @media screen and (max-width: 1280px) {
      .footerUrl {
        padding-right: 10px;
        font-size: 16px;
        li {
          padding: 0 8px;
        }
      }
      .footerSocial li {
        padding: 0 5px;
      }
    }
    @media screen and (max-width: 1160px) {
      .footerUrl {
        font-size: 14px;
      }
    }
    @media screen and (max-width: 980px) {
      .footerUrl {
        font-size: 13px;
        li {
          padding: 0 5px;
        }
      }
    }
    @media screen and (max-width: 860px) {
      .footerUrl {
        font-size: 12px;
        li {
          padding: 0 3px;
        }
      }
    }
    @media screen and (max-width: 820px) {
      .footerUrl {
        display: none;
      }
      .footerSocial {
        flex: 3;
        justify-content: flex-end;
      }
      .footerMobile {
        display: inline-block;
        .footerUrl {
          display: flex;
        }
      }
    }
    @media screen and (max-width: 520px) {
      .footerMobile {
        display: inline-block;
        .footerUrl {
          display: block;
          font-size: 14px;
          li {
            display: inline-block;
            position: relative;
            width: 100%;
            text-align: center;
            margin-bottom: 10px;
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}

.mainFooter .footerSocial .language {
  position: relative;
  .mainLg {
    cursor: pointer;
    display: flex;
  }
  .otherlg {
    position: absolute;
    width: 450px;
    height: auto;
    background-color: #00459c;
    color: #fff;
    border-radius: 20px;
    padding: 45px;
    right: -9px;
    bottom: 75px;
    display: none;
    a {
      text-decoration: none;
      display: inline-block;
      width: 50%;
      position: relative;
      margin-bottom: 30px;
      padding-right: 10px;
      span {
        color: #fff;
        text-decoration: none;
        font-weight: 600;
        font-size: 18px;
        padding-left: 10px;
      }
      &:nth-last-child(1) {
        margin-bottom: 0;
      }
      &:nth-last-child(2) {
        margin-bottom: 0;
      }
    }
    a > * {
      vertical-align: middle;
    }
    &:after {
      content: "";
      position: absolute;
      bottom: -14px;
      right: 15px;
      width: 0;
      height: 0;
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
      border-top: 15px solid #00459c;
      @include respond(mobileMedium) {
        bottom: -12px;
        right: 10px;
      }
    }
    @include respond(mobileMedium) {
      width: 300px;
      padding: 18px;
      bottom: 60px;
      a span {
        font-size: 14px;
        padding-left: 5px;
      }
    }
  }
  &.active {
    .otherlg {
      display: block;
    }
  }
}

.hide {
  display: none !important;
}

.adsFullSize {
  display: inline-block;
  position: relative;
  height: calc(100%);
  width: 100%;
  text-align: center;
}

/*
.humoqDetailsWrapper {
  .rightColAdsAbsolute {
    position: absolute;
    width: 300px;
    height: 600px;
    right: 0px;
    margin-right: 10px;
  }
  .rightColAds {
    @include gridRowCol(1, 4, 14, 17);
    @include respond(desktopWide) {
      @include gridRowCol(1, 4, 12, 15);
      min-width: 300px;
    }
    @media screen and (max-width: 1824px) {
      @include gridRowCol(1, 4, 12, 15);
    }
    @media screen and (max-width: 1730px) {
      @include gridRowCol(1, 4, 10, 13);
      min-width: 300px;
    }
    @media screen and (max-width: 1564px) {
      @include gridRowCol(1, 4, 10, 13);
      min-width: 300px;
    }
    @media screen and (max-width: 1444px) {
      @include gridRowCol(1, 4, 10, 13);
      min-width: 300px;
    }
    @media screen and (max-width: 1334px) {
      @include gridRowCol(1, 4, 10, 13);
      min-width: 300px;
    }
    @media screen and (max-width: 1284px) {
      @include gridRowCol(1, 4, 10, 13);
      min-width: 300px;
    }
    @media screen and (max-width: 1249px) {
      @include gridRowCol(1, 4, 9, 12);
      min-width: 300px;
    }
    @media screen and (max-width: 1168px) {
      display: none;
    }
    .adsFullSize:after {
      content: "advertisement";
      position: absolute;
      bottom: 0;
      width: 100%;
      left: 0;
      text-align: center;
      font-size: 10px;
      color: #006baa;
    }
  }
  .bottomColAds {
    display: flex;
    align-items: center;
    @include gridRowCol(7, 10, 4, 12);
    @media screen and (max-width: 1824px) {
      @include gridRowCol(7, 10, 4, 13);
    }
    @media screen and (max-width: 1684px) {
      @include gridRowCol(6, 9, 3, 11);
    }
    @media screen and (max-width: 1564px) {
      @include gridRowCol(6, 9, 2, 10);
    }
    @media screen and (max-width: 1444px) {
      @include gridRowCol(6, 9, 3, 9);
    }
    @media screen and (max-width: 1400px) {
      @include gridRowCol(6, 9, 1, 9);
    }
    @media screen and (max-width: 1084px) {
      @include gridRowCol(6, 9, 1, 7);
    }
    @media screen and (max-width: 768px) {
      display: none;
    }
    .adsFullSize {
      height: calc(100%);
      width: 100%;
    }
    .adsFullSize:after {
      content: "advertisement";
      position: absolute;
      top: calc(100% / 2 - 35px);
      width: auto;
      right: -77px;
      text-align: center;
      font-size: 10px;
      color: #006baa;
      transform-origin: 0 0;
      transform: rotate(90deg);
      padding-right: 15px;
      @media screen and (max-width: 1444px) {
        right: -80px;
      }
    }
  }
}
*/
.catScope {
  display: block;
  width: 100%;
  position: relative;
  margin: 50px 0 20px 0;
  .catBox {
    display: inline-block;
    position: relative;
    background: #fff;
    height: 55px;
    width: auto;
    margin-right: 20px;
    border-radius: 15px;
    border-top-left-radius: 0;
    margin-bottom: 20px;
    box-shadow: 0px 5px 8px 0px rgb(0 0 0 / 40%);
    min-width: 120px;
    padding-right: 20px;
    @include respond(mobileWide) {
      height: 42px;
      margin-right: 10px;
      margin-bottom: 10px;
      border-radius: 10px;
      border-top-left-radius: 0;
    }
    .catImg {
      position: absolute;
      width: 30px;
      top: 50%;
      text-align: center;
      margin: auto;
      transform: translateY(-50%);
      left: 20px;
      height: 30px;
      @include respond(mobileWide) {
        width: 25px;
        height: 25px;
      }
      .static-icon {
        display: inline-block;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      img {
        display: inline-block;
        position: relative;
        @include respond(mobileWide) {
          height: 50%;
          width: auto;
        }
      }
    }
    .catText {
      display: inline-block;
      text-transform: capitalize;
      position: relative;
      padding-left: 68px;
      top: 50%;
      transform: translateY(-50%);
      color: #006baa;
      font-size: 20px;
      @include respond(mobileWide) {
        padding-left: 50px;
        font-size: 14px;
      }
    }
    transition: box-shadow 0.3s ease;
    box-shadow: 0 0 0 transparent;
    border: none;

    &:hover {
      box-shadow: 0 8px 20px rgba(0, 107, 170, 0.9);
    }
  }
}

.intro {
  position: absolute;
  width: 100%;
  height: calc(100% - 50px);
  top: 0;
  left: 0;
  z-index: 3;
  background: linear-gradient(180deg, #ffbdf8 0%, #77d6ff 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition: all 0.54s ease;
  &.passive {
    opacity: 0;
  }
  .introHead {
    display: block;
    text-align: center;
    font-family: "Comfortaa", serif;
    font-weight: 400;
    color: #00459c;
    font-size: 27px;
    letter-spacing: 0.25em;
    line-height: 120%;
    margin: 50px 0 50px 0;
    @include respond(mobileWide) {
      margin: 20px 0 30px 0;
    }
    @include respond(mobile) {
      margin: 10px 0 20px 0;
    }
  }
  .playButtons {
    @include respond(mobileWide) {
      width: 70px;
    }
    @include respond(mobile) {
      width: 60px;
    }
  }
  .introUrl {
    display: block;
    text-align: center;
    font-family: "Comfortaa", serif;
    font-weight: 700;
    color: #00459c;
    font-size: 22px;
    line-height: 120%;
    margin: 35px 0 0 0;
    @include respond(mobileWide) {
      margin: 15px 0 0 0;
    }
    @include respond(mobile) {
      margin: 5px 0 0 0;
    }
  }
  @include respond(mobileWide) {
    img {
      width: 90px;
      height: auto;
    }
    .introHead {
      font-size: 18px;
    }
    .introUrl {
      font-size: 16px;
    }
  }
}

.humoqPopup {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 69, 156, 0.7);
  z-index: 999;
  top: 0;
  left: 0;
  display: none;
  &.show {
    display: block;
  }
  .hpClose {
    position: absolute;
    right: 30px;
    top: 30px;
    cursor: pointer;
    z-index: 2;
  }
  .hpBg {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 0;
  }
  .hpContent {
    position: absolute;
    max-width: 400px;
    width: 96%;
    height: auto;
    left: 50%;
    top: 50%;
    z-index: 2;
    transform: translate(-50%, -50%);
    background: #fff;
    border-radius: 20px;
    padding: 10px 30px 30px 30px;
    @include respond(mobileWide) {
      padding: 20px 30px 30px 30px;
    }
  }
  .hpLogo {
    display: inline-block;
    position: relative;
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
    img {
      @include respond(mobileWide) {
        width: 120px;
        height: auto;
      }
    }
  }
  .hpIcon {
    display: inline-block;
    position: relative;
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
    img {
      @include respond(mobileWide) {
        width: 130px;
        height: auto;
      }
    }
  }
  .hpText {
    display: inline-block;
    position: relative;
    width: 100%;
    text-align: center;
    font-weight: 700;
    font-size: 16px;
    color: #006baa;
    padding: 0 40px 30px 40px;
    @include respond(mobileWide) {
      font-size: 14px;
    }
  }
  .hpButtonScope {
    display: flex;
    align-content: space-between;
    justify-content: center;
    position: relative;
    width: 100%;
    text-align: center;
    font-weight: 700;
    .hpButton {
      display: flex;
      padding: 15px 25px;
      background: #00459c;
      color: #fff;
      font-size: 16px;
      font-weight: 600;
      border-radius: 14px;
      border-top-left-radius: 0;
      box-shadow: 0px 3.01887px 0px rgba(0, 107, 170, 0.2);
      cursor: pointer;
      @include respond(mobileWide) {
        font-size: 14px;
      }
    }
  }
  @include respond(mobileWide) {
    br.mobileHide {
      display: none;
    }
  }
}

.LoginModal,
.CreateAccountModal,
.ResetPasswordAccount {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.65);
  z-index: 999;
  top: 0;
  left: 0;
  &.show {
    display: block;
  }
  .lmClose {
    position: absolute;
    right: 30px;
    top: 30px;
    cursor: pointer;
    z-index: 2;
    @include respond(tablet) {
      display: none;
    }
  }
  .lmCloseMobile {
    display: none;
    position: absolute;
    right: 30px;
    top: 30px;
    cursor: pointer;
    z-index: 2;
    @include respond(tablet) {
      display: block;
    }
  }
  .lmBg {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 0;
  }
  .lmContent {
    position: absolute;
    display: flex;
    max-width: 1050px;
    width: 100%;
    height: auto;
    left: 50%;
    top: 50%;
    z-index: 2;
    transform: translate(-50%, -50%);
    background: #d6f2ff;
    border-radius: 22px;
    @include respond(tablet) {
      max-width: 615px;
      width: 96%;
      border-radius: 12px;
    }
    @include respond(mobileWide) {
      padding: 0;
    }
  }
  .lmContentLeftBlock {
    width: 60%;
    padding: 40px 50px;
    @include respond(tablet) {
      width: 100%;
    }
    @include respond(mobileWide) {
      padding: 20px 15px;
    }
    .lmLoginTitle {
      font-weight: 800;
      font-size: 32px;
      line-height: 44px;
      color: #006baa;
      @include respond(tablet) {
        font-size: 26px;
      }
    }
    .field-container {
      position: relative;
    }
    .field-container-checkbox {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 20px;
      @include respond(mobileWide) {
        font-size: 16px;
      }
      .field-container-checkbox-inner {
        display: flex;
        align-items: center;
      }
    }
    .field-placeholder {
      position: absolute;
      top: 12px;
      left: 20px;
      pointer-events: none;
      transition: all 0.14s ease-in-out;
      font-weight: 800;
      font-size: 14px;
      line-height: 19px;
      color: #006baa;
      @include respond(mobileWide) {
        font-size: 12px;
        top: 10px;
      }
    }
    input.field-input {
      border: none;
      padding: 30px 20px 12px 20px;
      margin-top: 32px;
      font-weight: 700;
      font-size: 18px;
      line-height: 25px;
      color: #006baa;
      display: block;
      box-sizing: border-box;
      width: 100%;
      height: 78px;
      bottom: 0px;
      background: #ffffff;
      box-shadow: 0px 3.01887px 3.01887px rgba(0, 0, 0, 0.25);
      border-radius: 0 22px 22px 22px;
      @include respond(tablet) {
        height: 68px;
        margin-top: 10px;
        border-radius: 0 15px 15px 15px;
      }
      @include respond(mobileWide) {
        height: 68px;
        font-size: 16px;
        margin-top: 10px;
      }
    }
    input.field-input:focus {
      outline: none;
    }
    ::-webkit-input-placeholder {
      color: #bcced9;
      font-weight: 700;
      font-size: 18px;
      @include respond(mobileWide) {
        font-size: 14px;
      }
    }
    ::-moz-placeholder {
      color: #bcced9;
      font-weight: 700;
      font-size: 18px;
      @include respond(mobileWide) {
        font-size: 14px;
      }
    }
    :-ms-input-placeholder {
      color: #bcced9;
      font-weight: 700;
      font-size: 18px;
      @include respond(mobileWide) {
        font-size: 14px;
      }
    }
    .checkbox-input {
      width: 22px;
      height: 22px;
      box-shadow: 0px 3.01887px 3.01887px rgba(0, 0, 0, 0.25);
      border-radius: 4px;
      border: 1px solid #7aa1b4;
      background-color: #ffffff;
      @include respond(mobileWide) {
        width: 18px;
        height: 18px;
      }
    }
    .checkboxLabel {
      font-weight: 800;
      font-size: 14px;
      line-height: 19px;
      color: #7aa1b4;
      padding-left: 5px;
    }
    .forgotPassword {
      font-weight: 800;
      font-size: 14px;
      line-height: 19px;
      text-decoration-line: underline;
      color: #006baa;
      cursor: pointer;
    }
    .submitButton {
      background-color: #006baa;
      height: 78px;
      width: 100%;
      color: #ffffff;
      font-weight: 800;
      font-size: 22px;
      line-height: 30px;
      text-align: center;
      color: #ffffff;
      border-radius: 0 22px 22px 22px;
      border: none;
      cursor: pointer;
      margin-top: 90px;
      &.submitButtonLogin {
        margin-top: 25px;
      }
      &.submitButtonResetPassword {
        margin-top: 45px;
      }
      @include respond(tablet) {
        margin-top: 30px;
        height: 58px;
        font-size: 16px;
        border-radius: 0 14px 14px 14px;
      }
      @include respond(mobileWide) {
        line-height: 22px;
      }
    }
    .orSignInWith {
      display: none;
      align-items: center;
      justify-content: center;
      color: #7aa1b4;
      margin-top: 40px;
      @include respond(tablet) {
        margin-top: 30px;
      }
      .orSignInLine {
        flex: 3;
        height: 1px;
        background-color: #7aa1b4;
        width: 100%;
      }
      .orSignInText {
        flex: 2;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 800;
        font-size: 14px;
        line-height: 19px;
        color: #7aa1b4;
        @include respond(mobile) {
          flex: 3;
        }
      }
    }
    .fullLine {
      display: none;
      @include respond(tablet) {
        display: block;
        height: 1px;
        background-color: #7aa1b4;
        width: 100%;
        margin-top: 20px;
      }
    }
    .signInButtonsBlock {
      display: none;
      justify-content: space-between;
      .signInButtonsGoogle {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #f6f8f9;
        border: 1px solid #ced4da;
        border-radius: 0 22px 22px 22px;
        height: 58px;
        max-width: 255px;
        width: 100%;
        margin-top: 20px;
        color: #db4437;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        margin-right: 20px;
        cursor: pointer;
        @include respond(tablet) {
          margin-top: 20px;
          border-radius: 0 12px 12px 12px;
        }
        @include respond(mobileWide) {
          height: 46px;
        }
        img {
          margin-right: 10px;
          @include respond(mobileWide) {
            width: 22px;
          }
        }
        strong {
          color: #7aa1b4;
          display: contents;
          font-weight: 500;
          @include respond(mobileWide) {
            display: none;
          }
        }
      }
      .signInButtonsFacebook {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #f6f8f9;
        border: 1px solid #ced4da;
        border-radius: 0 22px 22px 22px;
        height: 58px;
        max-width: 255px;
        width: 100%;
        margin-top: 20px;
        color: #006baa;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        cursor: pointer;
        @include respond(tablet) {
          margin-top: 20px;
          border-radius: 0 12px 12px 12px;
        }
        @include respond(mobileWide) {
          height: 46px;
        }
        img {
          margin-right: 10px;
          @include respond(mobileWide) {
            width: 22px;
          }
        }
        strong {
          color: #7aa1b4;
          display: contents;
          font-weight: 500;
          @include respond(mobileWide) {
            display: none;
          }
        }
      }
    }
    .signInButtonMobile {
      display: none;
      @include respond(tablet) {
        display: block;
        background-color: #f34a87;
        height: 58px;
        width: 100%;
        color: #ffffff;
        font-weight: 800;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        color: #ffffff;
        border-radius: 0 14px 14px 14px;
        border: none;
        cursor: pointer;
        margin-top: 20px;
      }
    }
  }
  .lmContentRightBlock {
    width: 40%;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #dfdfdf;
    border-radius: 0 22px 22px 0;
    background-image: url(../public/create-account-bg.png);
    background-position: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;
    &.loginPopupBg {
      background-image: url(../public/login-bg.png);
    }
    &.resetPasswordPopupBg {
      background-image: url(../public/reset-password-bg.png);
    }
    @include respond(tablet) {
      display: none;
    }
    .lmBgCharacter {
      position: absolute;
      left: 0;
      bottom: -30px;
      &.passwordResetBgCharacter {
        bottom: -80px;
      }
    }
    .lmContentText {
      position: absolute;
      top: 60px;
      left: 50%;
      transform: translateX(-50%);
      max-width: 250px;
      width: 100%;
      font-weight: 800;
      font-size: 32px;
      line-height: 120.4%;
      text-align: center;
      color: #ffffff;
      text-shadow: -2px 3px 0px #006baa;
      &.loginPopupContentText {
        top: 60px;
      }
      @include respond(tablet) {
        display: block;
      }
    }
    .lmContentSignUpButton {
      position: absolute;
      top: 130px;
      left: 50%;
      transform: translateX(-50%);
      background: #f6f8f9;
      border: 1px solid #ced4da;
      border-radius: 0 22px 22px 22px;
      box-shadow: 0px 3.01887px 3.01887px rgba(0, 0, 0, 0.25);
      height: 56px;
      max-width: 269px;
      width: 100%;
      margin-top: 30px;
      color: #006baa;
      font-style: normal;
      font-weight: 800;
      font-size: 22px;
      line-height: 30px;
      cursor: pointer;
      &.loginSignUpButton {
        top: 130px;
      }
    }
  }
}

.ProfileEditModal {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.65);
  z-index: 999;
  top: 0;
  left: 0;
  &.show {
    display: block;
  }
  .lmClose {
    position: absolute;
    right: 30px;
    top: 30px;
    cursor: pointer;
    z-index: 2;
    @include respond(tablet) {
      display: none;
    }
  }
  .lmCloseMobile {
    display: none;
    position: absolute;
    right: 30px;
    top: 30px;
    cursor: pointer;
    z-index: 2;
    @include respond(tablet) {
      display: block;
    }
  }
  .lmBg {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 0;
  }
  .lmContent {
    position: absolute;
    display: flex;
    max-width: 700px;
    width: 100%;
    height: auto;
    left: 50%;
    top: 50%;
    z-index: 2;
    transform: translate(-50%, -50%);
    background: #d6f2ff;
    border-radius: 22px;
    @include respond(tablet) {
      max-width: 615px;
      width: 96%;
      border-radius: 12px;
    }
    @include respond(mobileWide) {
      padding: 0;
    }
  }
  .lmContentBlock {
    width: 100%;
    padding: 40px 50px;
    @include respond(tablet) {
      width: 100%;
    }
    @include respond(mobileWide) {
      padding: 20px 15px;
    }
    .lmLoginTitle {
      font-weight: 800;
      font-size: 32px;
      line-height: 44px;
      color: #006baa;
      @include respond(tablet) {
        font-size: 26px;
      }
    }
    .field-container {
      position: relative;
    }
    .field-container-half {
      position: relative;
      float: left;
      width: 49%;
      margin-right: 2%;
      @include respond(mobileWide) {
        width: 100%;
        margin-right: 0%;
      }
      &:nth-child(2n + 1) {
        margin-right: 0;
      }
      .field-placeholder {
        position: absolute;
        top: 44px;
        left: 20px;
        pointer-events: none;
        transition: all 0.14s ease-in-out;
        font-weight: 800;
        font-size: 14px;
        line-height: 19px;
        color: #006baa;
        @include respond(tablet) {
          top: 21px;
        }
        @include respond(mobileWide) {
          font-size: 12px;
          top: 26px;
        }
      }
    }
    .field-container-checkbox {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 20px;
      @include respond(mobileWide) {
        font-size: 16px;
      }
      .field-container-checkbox-inner {
        display: flex;
        align-items: center;
      }
    }
    .field-placeholder {
      position: absolute;
      top: 12px;
      left: 20px;
      pointer-events: none;
      transition: all 0.14s ease-in-out;
      font-weight: 800;
      font-size: 14px;
      line-height: 19px;
      color: #006baa;
      @include respond(mobileWide) {
        font-size: 12px;
        top: 10px;
      }
    }
    input.field-input {
      position: relative;
      border: none;
      padding: 30px 20px 12px 20px;
      margin-top: 32px;
      font-weight: 700;
      font-size: 18px;
      line-height: 25px;
      color: #006baa;
      display: block;
      box-sizing: border-box;
      width: 100%;
      height: 78px;
      bottom: 0px;
      background: #ffffff;
      box-shadow: 0px 3.01887px 3.01887px rgba(0, 0, 0, 0.25);
      border-radius: 0 22px 22px 22px;
      @include respond(tablet) {
        height: 68px;
        margin-top: 10px;
        border-radius: 0 15px 15px 15px;
      }
      @include respond(mobileWide) {
        height: 61px;
        font-size: 16px;
        margin-top: 16px;
      }
      &.facebook {
        background-image: url(facebook-icon.png);
        background-position: 20px 38px;
        background-size: 21.67px 21.54px;
        background-repeat: no-repeat;
        padding-left: 50px;
        @include respond(tablet) {
          background-size: 20.67px 20.54px;
          background-position: 20px 32px;
        }
        @include respond(mobileWide) {
          background-size: 15px 14.91px;
          background-position: 20px 33px;
          padding-left: 42px;
        }
      }
      &.instagram {
        background-image: url(instagram.png);
        background-position: 20px 38px;
        background-size: 21.67px 21.67px;
        background-repeat: no-repeat;
        padding-left: 50px;
        @include respond(tablet) {
          background-size: 20.67px 20.67px;
          background-position: 20px 32px;
        }
        @include respond(mobileWide) {
          background-size: 15px 15px;
          background-position: 20px 33px;
          padding-left: 42px;
        }
      }
      &.twitter {
        background-image: url(twitter.png);
        background-position: 20px 38px;
        background-size: 22.08px 17.95px;
        background-repeat: no-repeat;
        padding-left: 50px;
        @include respond(tablet) {
          background-size: 21.08px 16.95px;
          background-position: 20px 36px;
        }
        @include respond(mobileWide) {
          background-size: 15.29px 12.43px;
          background-position: 20px 35px;
          padding-left: 42px;
        }
      }
      &.pinterest {
        background-image: url(pinterest.png);
        background-position: 20px 38px;
        background-size: 21.67px 21.67px;
        background-repeat: no-repeat;
        padding-left: 50px;
        @include respond(tablet) {
          background-size: 20.67px 20.67px;
          background-position: 20px 32px;
        }
        @include respond(mobileWide) {
          background-size: 15px 15px;
          background-position: 20px 33px;
          padding-left: 42px;
        }
      }
    }
    input.field-input:focus {
      outline: none;
    }
    ::-webkit-input-placeholder {
      color: #bcced9;
      font-weight: 700;
      font-size: 18px;
      @include respond(mobileWide) {
        font-size: 14px;
      }
    }
    ::-moz-placeholder {
      color: #bcced9;
      font-weight: 700;
      font-size: 18px;
      @include respond(mobileWide) {
        font-size: 14px;
      }
    }
    :-ms-input-placeholder {
      color: #bcced9;
      font-weight: 700;
      font-size: 18px;
      @include respond(mobileWide) {
        font-size: 14px;
      }
    }
    .checkbox-input {
      width: 22px;
      height: 22px;
      box-shadow: 0px 3.01887px 3.01887px rgba(0, 0, 0, 0.25);
      border-radius: 4px;
      border: 1px solid #7aa1b4;
      background-color: #ffffff;
      @include respond(mobileWide) {
        width: 18px;
        height: 18px;
      }
    }
    .checkboxLabel {
      font-weight: 800;
      font-size: 14px;
      line-height: 19px;
      color: #7aa1b4;
      padding-left: 5px;
    }
    .forgotPassword {
      font-weight: 800;
      font-size: 14px;
      line-height: 19px;
      text-decoration-line: underline;
      color: #006baa;
      cursor: pointer;
    }
    .submitButton {
      background-color: #006baa;
      height: 78px;
      width: 100%;
      color: #ffffff;
      font-weight: 800;
      font-size: 22px;
      line-height: 30px;
      text-align: center;
      color: #ffffff;
      border-radius: 0 22px 22px 22px;
      border: none;
      cursor: pointer;
      margin-top: 90px;
      &.submitButtonLogin {
        margin-top: 25px;
      }
      &.submitButtonResetPassword {
        margin-top: 45px;
      }
      @include respond(tablet) {
        margin-top: 30px;
        height: 58px;
        font-size: 16px;
        border-radius: 0 14px 14px 14px;
      }
      @include respond(mobileWide) {
        line-height: 22px;
      }
    }
    .orSignInWith {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #7aa1b4;
      margin-top: 40px;
      @include respond(tablet) {
        margin-top: 30px;
      }
      .orSignInLine {
        flex: 3;
        height: 1px;
        background-color: #7aa1b4;
        width: 100%;
      }
      .orSignInText {
        flex: 2;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 800;
        font-size: 14px;
        line-height: 19px;
        color: #7aa1b4;
        @include respond(mobile) {
          flex: 3;
        }
      }
    }
    .fullLine {
      display: none;
      @include respond(tablet) {
        display: block;
        height: 1px;
        background-color: #7aa1b4;
        width: 100%;
        margin-top: 20px;
      }
    }
    .signInButtonsBlock {
      display: flex;
      justify-content: space-between;
      .signInButtonsGoogle {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #f6f8f9;
        border: 1px solid #ced4da;
        border-radius: 0 22px 22px 22px;
        height: 58px;
        max-width: 255px;
        width: 100%;
        margin-top: 20px;
        color: #db4437;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        margin-right: 20px;
        cursor: pointer;
        @include respond(tablet) {
          margin-top: 20px;
          border-radius: 0 12px 12px 12px;
        }
        @include respond(mobileWide) {
          height: 46px;
        }
        img {
          margin-right: 10px;
          @include respond(mobileWide) {
            width: 22px;
          }
        }
        strong {
          color: #7aa1b4;
          display: contents;
          font-weight: 500;
          @include respond(mobileWide) {
            display: none;
          }
        }
      }
      .signInButtonsFacebook {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #f6f8f9;
        border: 1px solid #ced4da;
        border-radius: 0 22px 22px 22px;
        height: 58px;
        max-width: 255px;
        width: 100%;
        margin-top: 20px;
        color: #006baa;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        cursor: pointer;
        @include respond(tablet) {
          margin-top: 20px;
          border-radius: 0 12px 12px 12px;
        }
        @include respond(mobileWide) {
          height: 46px;
        }
        img {
          margin-right: 10px;
          @include respond(mobileWide) {
            width: 22px;
          }
        }
        strong {
          color: #7aa1b4;
          display: contents;
          font-weight: 500;
          @include respond(mobileWide) {
            display: none;
          }
        }
      }
    }
    .signInButtonMobile {
      display: none;
      @include respond(tablet) {
        display: block;
        background-color: #f34a87;
        height: 58px;
        width: 100%;
        color: #ffffff;
        font-weight: 800;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        color: #ffffff;
        border-radius: 0 14px 14px 14px;
        border: none;
        cursor: pointer;
        margin-top: 20px;
      }
    }
  }
}

.passwordEyeOpener {
  display: flex;
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  img {
    width: 29px;
  }
}

.swiper-grid-column > .swiper-wrapper {
  flex-direction: inherit !important;
}

.swiper-slide {
  margin-top: 0 !important;
}

.swiper-pagination {
  position: absolute;
  top: 0px;
  right: 0;
  text-align: right !important;
}

.swiper-button-next,
.swiper-button-prev {
  z-index: 99 !important;
}

.commentBlock {
  grid-row-start: 10;
  grid-row-end: 14;
  grid-column-start: 2;
  grid-column-end: 8;
  position: relative;
  float: left;
  background-color: #ffffff;
  border-radius: 0 10px 10px 10px;
  box-shadow: 0px 2.99856px 2.99856px rgba(0, 0, 0, 0.25);
  padding: 15px 20px;
  box-sizing: border-box;
  width: 100%;
  overflow: auto;
  @include respond(desktopWide) {
    grid-column-start: 2;
    grid-column-end: 7;
    grid-row-start: 8;
    grid-row-end: 13;
  }
  @media screen and (max-width: 1824px) {
    grid-column-start: 2;
    grid-column-end: 7;
    grid-row-start: 8;
  }
  @media screen and (max-width: 1730px) {
    grid-column-start: 2;
    grid-column-end: 6;
    grid-row-start: 7;
    grid-row-end: 12;
  }

  @media screen and (max-width: 1564px) {
    grid-column-start: 2;
    grid-column-end: 6;
    grid-row-start: 7;
    grid-row-end: 12;
  }
  @media screen and (max-width: 1444px) {
    grid-column-start: 2;
    grid-column-end: 6;
    grid-row-start: 7;
  }
  @media screen and (max-width: 1334px) {
    grid-row-start: 7;
    grid-column-start: 2;
    grid-column-end: 6;
    grid-row-end: 12;
  }
  @media screen and (max-width: 1284px) {
    grid-row-start: 7;
  }
  @media screen and (max-width: 1120px) {
    display: none;
    grid-column-start: 2;
    grid-column-end: 5;
  }
  .commentSendForm {
    .commentSendFormTop {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
      .commentSendFormTitle {
        font-weight: 900;
        font-size: 18px;
        line-height: 25px;
        color: #006baa;
      }
      .commentSendFormCount {
        font-weight: 900;
        font-size: 18px;
        line-height: 25px;
        color: #7aa1b4;
      }
    }
    .commentSendFormContent {
      position: relative;
      display: block;
      width: 100%;
      .textarea-container {
        display: flex;
        position: relative;
        textarea {
          width: 100%;
          height: 150px;
          box-sizing: border-box;
          padding: 10px 100px 10px 10px;
          background: #f0f8fd;
          border: 1.5px solid #c3d5e0;
          border-radius: 10px;
          &::placeholder {
            color: #7aa1b4;
            font-weight: 800;
            font-size: 16px;
          }
        }
        button {
          position: absolute;
          bottom: 12px;
          right: 10px;
          background: #006baa;
          border-radius: 0px 15.0943px 15.0943px 15.0943px;
          padding: 11px 22px;
          cursor: pointer;
          color: #ffffff;
          border: transparent;
        }
        .noLoginStateTextarea {
          display: flex;
          width: 100%;
          background: #f0f8fd;
          border: 1.5px solid #c3d5e0;
          border-radius: 10px;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          padding: 38px 0;
          .noLoginStateDesc {
            font-weight: 800;
            font-size: 16px;
            line-height: 22px;
            letter-spacing: 0.529746px;
            color: #006baa;
            padding-bottom: 10px;
          }
          .noLoginStateButton {
            position: relative;
            bottom: inherit;
            right: inherit;
          }
        }
      }
    }
  }
  .commentShowBlock {
    position: relative;
    .commentShowTop {
      margin-top: 30px;
      margin-bottom: 18px;
      .commentShowTitle {
        font-weight: 900;
        font-size: 18px;
        line-height: 32px;
        color: #006baa;
      }
    }
    .commentShowContent {
      .commentShowItem {
        background: #006baa;
        border: 1.5px solid #f0f8fd;
        border-radius: 10px;
        padding: 25px 20px;
        margin-bottom: 10px;
        .commentShowItemTop {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 10px;
          .commentShowItemUserName {
            display: flex;
            align-items: center;
            font-weight: 900;
            font-size: 18px;
            line-height: 25px;
            color: #ffffff;
            img {
              width: 20px;
              height: 20px;
              border: 1px solid #fff;
              border-radius: 50%;
              overflow: hidden;
              margin-right: 8px;
            }
          }
          .commentShowItemDate {
            font-weight: 600;
            font-size: 14px;
            line-height: 19px;
            color: #ffffff;
          }
        }
        .commentShowItemContent {
          font-weight: 400;
          font-size: 14px;
          line-height: 19px;
          color: #ffffff;
        }
      }
      .commentEmptyState {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 50px;
        img {
          width: 89px;
        }
        .commentEmptyStateDesc {
          font-weight: 800;
          font-size: 18px;
          line-height: 22px;
          text-align: center;
          color: #006baa;
          padding-top: 10px;
          width: 170px;
        }
      }
    }
    .sliderNavigationPaginationBlock {
      position: absolute;
      right: 0;
      top: 0;
      display: flex;
      align-items: center;
      .previousButton {
        margin-right: 5px;
      }
      .nextButton {
        margin-left: 5px;
      }
      img {
        display: block;
        width: 32px;
        height: 32px;
        cursor: pointer;
      }
      .customPaginationDiv {
        .swiper-pagination-bullet {
          margin: 0 2px;
        }
      }
    }
  }
}

.gameplayLeaderboardBlock {
  grid-row-start: 10;
  grid-row-end: 14;
  grid-column-start: 8;
  grid-column-end: 14;
  position: relative;
  float: left;
  background-color: #ffffff;
  border-radius: 0 10px 10px 10px;
  box-shadow: 0px 2.99856px 2.99856px rgba(0, 0, 0, 0.25);
  padding: 0 20px 15px 20px;
  box-sizing: border-box;
  width: 100%;
  overflow: auto;
  @include respond(desktopWide) {
    grid-row-start: 8;
    grid-column-start: 7;
    grid-column-end: 12;
    grid-row-end: 13;
  }
  @media screen and (max-width: 1730px) {
    grid-row-start: 7;
    grid-column-start: 6;
    grid-column-end: 10;
    grid-row-end: 12;
  }
  @media screen and (max-width: 1564px) {
    grid-row-start: 7;
    grid-row-end: 12;
  }
  @media screen and (max-width: 1444px) {
    grid-row-start: 7;
  }
  @media screen and (max-width: 1334px) {
    grid-row-start: 7;
    grid-column-start: 6;
    grid-column-end: 10;
  }
  @media screen and (max-width: 1284px) {
    grid-row-start: 7;
    grid-column-start: 6;
    grid-column-end: 10;
  }
  @media screen and (max-width: 1120px) {
    display: none;
    grid-column-start: 5;
    grid-column-end: 9;
  }
  .gameplayLeaderboardTable {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 10px;
    thead {
      tr {
        th {
          font-weight: 800;
          font-size: 14px;
          line-height: 19px;
          color: #7aa1b4;
          position: sticky;
          top: 0px;
          padding: 6px 0;
          z-index: 1;
          background-color: #fff;
          &:nth-child(1) {
            font-weight: 900;
            font-size: 18px;
            line-height: 25px;
            color: #006baa;
            text-align: left;
          }
        }
      }
    }
    tbody {
      tr {
        border: 1px solid #c3d5df;
        border-radius: 15px;
        td {
          border-width: 1px;
          &:nth-child(1) {
            padding: 10px 8px;
            text-align: center;
            background: #bcced9;
            color: #315468;
            border-top-left-radius: 12px;
            border-bottom-left-radius: 12px;
            border-color: inherit;
            border-style: solid;
            border-width: 1px;
            &.firstPosition {
              background: #ffba35;
              color: #c15100;
            }
            &.secondPosition {
              background: #aa66ff;
              color: #6623ba;
            }
            &.thirdPosition {
              background: #ff6666;
              color: #c21212;
            }
          }
          &:nth-child(2) {
            display: flex;
            align-items: center;
            padding: 10px 8px;
            font-weight: 800;
            font-size: 16px;
            line-height: 22px;
            color: #006baa;
            border-color: inherit;
            border-style: solid;
            border-right-width: 0;
            border-left-width: 0;
            @media screen and (max-width: 1520px) {
              font-size: 14px;
            }
            img {
              width: 36px;
              margin-right: 5px;
              @media screen and (max-width: 1520px) {
                width: 30px;
              }
            }
          }
          &:nth-child(3) {
            font-weight: 800;
            font-size: 16px;
            line-height: 22px;
            text-align: center;
            color: #7aa1b4;
            border-color: inherit;
            border-style: solid;
            border-left: 1px solid #bcced9;
            border-right: 1px solid #bcced9;
            @media screen and (max-width: 1520px) {
              font-size: 14px;
            }
          }
          &:nth-child(4) {
            text-align: center;
            border-color: inherit;
            border-style: solid;
            border-width: 1px;
            border-left-width: 0;
            border-top-right-radius: 12px;
            border-bottom-right-radius: 12px;
            img {
              width: 18.5px;
              margin: 0 4px;
              @media screen and (max-width: 1520px) {
                margin: 0 3px;
              }
            }
          }
        }
      }
    }
  }
}

.commentsAndLeaderboardMobile {
  display: none;
  grid-row-start: 6;
  grid-row-end: 12;
  grid-column-start: 2;
  grid-column-end: 9;
  position: relative;
  float: left;
  background-color: #ffffff;
  border-radius: 22px;
  box-shadow: 0px 2.99856px 2.99856px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  width: 100%;
  overflow: auto;
  z-index: 0;
  @media screen and (max-width: 1120px) {
    display: block;
    @include gridRowCol(7, 13, 1, 10);
  }
  @media screen and (max-width: 964px) {
    @include gridRowCol(7, 13, 1, 10);
  }
  @media screen and (max-width: 844px) {
    @include gridRowCol(8, 13, 1, 10);
  }
  @media screen and (max-width: 768px) {
    @include gridRowCol(6, 12, 1, 7);
  }
  @media screen and (max-width: 724px) {
    @include gridRowCol(5, 10, 1, 6);
  }
  @media screen and (max-width: 591px) {
    @include gridRowCol(5, 10, 1, 5);
  }
  @media screen and (max-width: 473px) {
    @include gridRowCol(4, 10, 1, 4);
  }
  .commentsAndLeaderboardTab {
    position: sticky;
    top: 0;
    display: flex;
    background-color: #006baa;
    border-bottom: 2px solid #c3d5df;
    text-align: center;
    z-index: 9;
    .commentsAndLeaderboardTabItem {
      font-weight: 900;
      font-size: 18px;
      line-height: 25px;
      color: #fff;
      width: 50%;
      padding: 12px;
      &.active {
        background-color: #fff;
        color: #006baa;
      }
    }
  }
  .commentsContent {
    padding: 15px 20px;
    .commentSendForm {
      .commentSendFormTop {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
        .commentSendFormTitle {
          font-weight: 900;
          font-size: 18px;
          line-height: 25px;
          color: #006baa;
        }
        .commentSendFormCount {
          font-weight: 900;
          font-size: 18px;
          line-height: 25px;
          color: #7aa1b4;
        }
      }
      .commentSendFormContent {
        position: relative;
        display: block;
        width: 100%;
        .textarea-container {
          display: flex;
          position: relative;
          textarea {
            width: 100%;
            height: 150px;
            box-sizing: border-box;
            padding: 10px 100px 10px 10px;
            background: #f0f8fd;
            border: 1.5px solid #c3d5e0;
            border-radius: 10px;
            &::placeholder {
              color: #7aa1b4;
              font-weight: 800;
              font-size: 16px;
            }
          }
          button {
            position: absolute;
            bottom: 12px;
            right: 10px;
            background: #006baa;
            border-radius: 0px 15.0943px 15.0943px 15.0943px;
            padding: 11px 22px;
            cursor: pointer;
            color: #ffffff;
            border: transparent;
          }
          .noLoginStateTextarea {
            display: flex;
            width: 100%;
            background: #f0f8fd;
            border: 1.5px solid #c3d5e0;
            border-radius: 10px;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            padding: 38px 0;
            .noLoginStateDesc {
              font-weight: 800;
              font-size: 16px;
              line-height: 22px;
              letter-spacing: 0.529746px;
              color: #006baa;
              padding-bottom: 10px;
            }
            .noLoginStateButton {
              position: relative;
              bottom: inherit;
              right: inherit;
            }
          }
        }
      }
    }
    .commentShowBlock {
      position: relative;
      .commentShowTop {
        margin-top: 30px;
        margin-bottom: 15px;
        .commentShowTitle {
          font-weight: 900;
          font-size: 18px;
          line-height: 25px;
          color: #006baa;
        }
      }
      .commentShowContent {
        .commentShowItem {
          background: #006baa;
          border: 1.5px solid #f0f8fd;
          border-radius: 10px;
          padding: 25px 20px;
          margin-bottom: 10px;
          .commentShowItemTop {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;
            .commentShowItemUserName {
              display: flex;
              align-items: center;
              font-weight: 900;
              font-size: 18px;
              line-height: 25px;
              color: #ffffff;
              @include respond(mobileWide) {
                font-size: 16px;
              }
              img {
                width: 20px;
                height: 20px;
                border: 1px solid #fff;
                border-radius: 50%;
                overflow: hidden;
                margin-right: 8px;
              }
            }
            .commentShowItemDate {
              font-weight: 600;
              font-size: 14px;
              line-height: 19px;
              color: #ffffff;
            }
          }
          .commentShowItemContent {
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            color: #ffffff;
          }
        }
        .commentEmptyState {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          padding: 50px;
          img {
            width: 89px;
          }
          .commentEmptyStateDesc {
            font-weight: 800;
            font-size: 18px;
            line-height: 22px;
            text-align: center;
            color: #006baa;
            padding-top: 10px;
            width: 170px;
          }
        }
      }
      .sliderNavigationPaginationBlock {
        position: absolute;
        right: 0;
        top: 0;
        display: flex;
        align-items: center;
        .previousButton {
          margin-right: 5px;
        }
        .nextButton {
          margin-left: 5px;
        }
        img {
          display: block;
          width: 28px;
          height: 28px;
          cursor: pointer;
        }
        .customPaginationDivMobile {
          .swiper-pagination-bullet {
            margin: 0 2px;
          }
        }
      }
    }
  }
  .loaderboardContent {
    padding: 15px 20px;
    @include respond(mobileWide) {
      padding: 10px 10px;
    }
    .gameplayLeaderboardTable {
      width: 100%;
      border-collapse: separate;
      border-spacing: 0 10px;
      thead {
        tr {
          th {
            font-weight: 800;
            font-size: 14px;
            line-height: 19px;
            color: #7aa1b4;
            position: sticky;
            top: 50px;
            padding: 6px 0;
            z-index: 1;
            background-color: #fff;
            &:nth-child(1) {
              font-weight: 900;
              font-size: 18px;
              line-height: 25px;
              color: #006baa;
              text-align: left;
            }
          }
        }
      }
      tbody {
        tr {
          border: 1px solid #c3d5df;
          border-radius: 15px;
          td {
            border-width: 1px;
            &:nth-child(1) {
              padding: 10px 8px;
              text-align: center;
              background: #bcced9;
              color: #315468;
              border-top-left-radius: 12px;
              border-bottom-left-radius: 12px;
              border-color: inherit;
              border-style: solid;
              border-width: 1px;
              &.firstPosition {
                background: #ffba35;
                color: #c15100;
              }
              &.secondPosition {
                background: #aa66ff;
                color: #6623ba;
              }
              &.thirdPosition {
                background: #ff6666;
                color: #c21212;
              }
            }
            &:nth-child(2) {
              display: flex;
              align-items: center;
              padding: 10px 8px;
              font-weight: 800;
              font-size: 16px;
              line-height: 22px;
              color: #006baa;
              border-color: inherit;
              border-style: solid;
              border-right-width: 0;
              border-left-width: 0;
              @media screen and (max-width: 1520px) {
                font-size: 14px;
              }
              img {
                width: 36px;
                margin-right: 5px;
                @media screen and (max-width: 1520px) {
                  width: 30px;
                }
              }
            }
            &:nth-child(3) {
              font-weight: 800;
              font-size: 16px;
              line-height: 22px;
              text-align: center;
              color: #7aa1b4;
              border-color: inherit;
              border-style: solid;
              border-left: 1px solid #bcced9;
              border-right: 1px solid #bcced9;
              @media screen and (max-width: 1520px) {
                font-size: 14px;
              }
            }
            &:nth-child(4) {
              text-align: center;
              border-color: inherit;
              border-style: solid;
              border-width: 1px;
              border-left-width: 0;
              border-top-right-radius: 12px;
              border-bottom-right-radius: 12px;
              img {
                width: 22px;
                margin: 0 4px;
                @media screen and (max-width: 1520px) {
                  margin: 0 3px;
                }
              }
            }
            .socialMediaScope {
              position: relative;
              .socialMediaMobileBlock {
                position: absolute;
                background: #ffffff;
                box-shadow: 0px 2.99856px 2.99856px rgba(0, 0, 0, 0.15);
                border-radius: 11px;
                width: 100%;
                min-width: 150px;
                padding: 10px;
                left: 50%;
                transform: translateX(-80%);
                top: 45px;
                z-index: 1;
                border: 1px solid #c3d5df;
                &.dBlock {
                  display: block !important;
                }
                .triangle {
                  // using clip-path
                  display: block;
                  height: 20px;
                  width: 20px;
                  background-color: inherit;
                  border: inherit;
                  position: absolute;
                  top: -10px;
                  right: 20px;
                  clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
                  transform: rotate(135deg);
                  border-radius: 0 0 0 0.25em;
                }
                .socialMediaItemMobile {
                  display: flex;
                  align-items: center;
                  font-weight: 800;
                  font-size: 14px;
                  line-height: 17px;
                  color: #006baa;
                  padding: 12px 0;
                  border-bottom: 1px solid #c3d5df;
                  a {
                    text-decoration: none;
                    color: #006baa;
                  }
                  img {
                    margin-right: 8px;
                  }
                  &:last-child {
                    border-bottom: 0px;
                  }
                }
              }
            }

            .noUserLeaderboard {
              td {
                background-color: transparent !important;
                border-top-left-radius: 0 !important;
                border-bottom-left-radius: 0 !important;
                border-width: 0 !important;
                .noUserLeaderboardBlock {
                  position: absolute;
                  left: 50%;
                  top: 50%;
                  transform: translate(-50%, -50%);
                  .noUserLeaderboardText {
                    font-weight: 800;
                    font-size: 18px;
                    line-height: 22px;
                    text-align: center;
                    color: #006baa;
                    padding-top: 10px;
                    width: 170px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.Scope404 {
  position: relative;
  width: 100%;
  text-align: center;
  .Title404 {
    font-weight: 900;
    font-size: 55px;
    line-height: 80px;
    color: #f34a87;
    @include respond(mobileWide) {
      font-size: 40px;
      line-height: 55px;
    }
  }
  .SubDesc404 {
    font-weight: 900;
    font-size: 25px;
    line-height: 35px;
    color: #00459c;
    margin-bottom: 20px;
    @include respond(mobileWide) {
      font-size: 20px;
      line-height: 25px;
    }
  }
  img {
    max-width: 800px;
    width: 100%;
  }
}

.dBlock {
  display: block;
}

.dNone {
  display: none;
}

.noUserLeaderboard {
  td {
    background-color: transparent !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-width: 0 !important;
    .noUserLeaderboardBlock {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      .noUserLeaderboardText {
        font-weight: 800;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        color: #006baa;
        padding-top: 10px;
        width: 170px;
      }
    }
  }
}

.leaderboardScope {
  position: relative;
  max-width: 1011px;
  width: 100%;
  margin: 0 auto;
  .leaderboardMainTitle {
    font-weight: 900;
    font-size: 25.8251px;
    line-height: 35px;
    text-align: center;
    color: #006baa;
    padding-bottom: 22px;
    @include respond(mobileWide) {
      font-size: 20px;
      padding-bottom: 10px;
    }
  }
  .leaderboardBestPositionBlock {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
    .leaderboardBestPositionUser {
      text-align: center;
      display: flex;
      flex-direction: column;
      &.positionOne {
        order: 2;
        margin: 0 100px;
        @media screen and (max-width: 1120px) {
          margin: 0 60px;
        }
        @include respond(mobileWide) {
          margin: 0 38px;
        }
        .leaderboardBestPositionBackDiv {
          position: relative;
          background: linear-gradient(180deg, #ffba35 0%, #ff7235 142.68%);
          width: 195px;
          height: 195px;
          border-radius: 50%;
          @include respond(mobileWide) {
            width: 85px;
            height: 85px;
          }
          .leaderboardBestPositionTrophyIcon {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top: -28px;
            @include respond(mobileWide) {
              top: -13px;
            }
            img {
              width: 63px;
              @include respond(mobileWide) {
                width: 27px;
              }
            }
          }
          .leaderboardBestPositionMiddleDiv {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background: radial-gradient(
              50% 50% at 50% 50%,
              #90caff 0%,
              #5d63ff 100%
            );
            width: 183px;
            height: 183px;
            border-radius: 50%;
            @include respond(mobileWide) {
              width: 73px;
              height: 73px;
            }
            img {
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              width: 130px;
              @include respond(mobileWide) {
                width: 50px;
                height: 50px;
              }
            }
          }
          .leaderboardBestPositionNumberBlock {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            bottom: -15px;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            left: 50%;
            transform: translateX(-50%);
            background: linear-gradient(180deg, #ffba35 0%, #ff7235 142.68%);
            @include respond(mobileWide) {
              bottom: -10px;
              width: 23px;
              height: 23px;
            }
            .leaderboardBestPositionNumber {
              font-weight: 900;
              font-size: 26px;
              line-height: 33px;
              color: #c15100;
              @include respond(mobileWide) {
                font-size: 14px;
                line-height: 19px;
              }
            }
          }
        }
        .leaderboardBestPositionPlayerName {
          font-weight: 700;
          font-size: 26px;
          line-height: 40px;
          color: #c15100;
          padding-top: 20px;
          a {
            text-decoration: none;
            color: inherit;
          }
          @include respond(mobileWide) {
            font-size: 12px;
            line-height: 20px;
          }
        }
        .leaderboardBestPositionPlayerScore {
          font-size: 19.8575px;
          line-height: 32px;
          letter-spacing: 0.511161px;
          color: #006baa;
          @include respond(mobileWide) {
            font-size: 12px;
            line-height: 20px;
          }
        }
      }
      &.positionTwo {
        order: 1;
        padding-top: 25px;
        .leaderboardBestPositionBackDiv {
          position: relative;
          background: linear-gradient(180deg, #c08ffe 0%, #9848d8 100%);
          width: 170px;
          height: 170px;
          border-radius: 50%;
          @include respond(mobileWide) {
            width: 75px;
            height: 75px;
          }
          .leaderboardBestPositionTrophyIcon {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top: -28px;
            @include respond(mobileWide) {
              top: -12px;
            }
            img {
              width: 55px;
              @include respond(mobileWide) {
                width: 24px;
              }
            }
          }
          .leaderboardBestPositionMiddleDiv {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background: radial-gradient(
              50% 50% at 50% 50%,
              #c9ff93 0%,
              #31d468 100%
            );
            width: 158px;
            height: 158px;
            border-radius: 50%;
            @include respond(mobileWide) {
              width: 63px;
              height: 63px;
            }
            img {
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              width: 115px;
              @include respond(mobileWide) {
                width: 45px;
              }
            }
          }
          .leaderboardBestPositionNumberBlock {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            bottom: -15px;
            width: 45px;
            height: 45px;
            border-radius: 50%;
            left: 50%;
            transform: translateX(-50%);
            background: linear-gradient(180deg, #c190ff 0%, #9948d9 142.68%);
            @include respond(mobileWide) {
              width: 20px;
              height: 20px;
              bottom: -8px;
            }
            .leaderboardBestPositionNumber {
              font-weight: 900;
              font-size: 24.2703px;
              line-height: 33px;
              color: #6623ba;
              @include respond(mobileWide) {
                font-size: 14px;
                line-height: 20px;
              }
            }
          }
        }
        .leaderboardBestPositionPlayerName {
          font-weight: 700;
          font-size: 24.2703px;
          line-height: 40px;
          color: #6623ba;
          padding-top: 20px;
          a {
            text-decoration: none;
            color: inherit;
          }
          @include respond(mobileWide) {
            font-size: 12px;
            line-height: 20px;
          }
        }
        .leaderboardBestPositionPlayerScore {
          font-size: 19.8575px;
          line-height: 32px;
          letter-spacing: 0.511161px;
          color: #006baa;
          @include respond(mobileWide) {
            font-size: 12px;
            line-height: 20px;
          }
        }
      }
      &.positionThree {
        order: 3;
        padding-top: 25px;
        .leaderboardBestPositionBackDiv {
          position: relative;
          background: linear-gradient(180deg, #ff6767 0%, #dd2d2d 142.68%);
          width: 170px;
          height: 170px;
          border-radius: 50%;
          @include respond(mobileWide) {
            width: 75px;
            height: 75px;
          }
          .leaderboardBestPositionTrophyIcon {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top: -28px;
            @include respond(mobileWide) {
              top: -12px;
            }
            img {
              width: 55px;
              @include respond(mobileWide) {
                width: 24px;
              }
            }
          }
          .leaderboardBestPositionMiddleDiv {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background: radial-gradient(
              50% 50% at 50% 50%,
              #f8e270 0%,
              #fba600 100%
            );
            width: 158px;
            height: 158px;
            border-radius: 50%;
            @include respond(mobileWide) {
              width: 63px;
              height: 63px;
            }
            img {
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              width: 115px;
              @include respond(mobileWide) {
                width: 45px;
              }
            }
          }
          .leaderboardBestPositionNumberBlock {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            bottom: -15px;
            width: 45px;
            height: 45px;
            border-radius: 50%;
            left: 50%;
            transform: translateX(-50%);
            background: linear-gradient(180deg, #ff6767 0%, #dd2d2d 142.68%);
            @include respond(mobileWide) {
              width: 20px;
              height: 20px;
              bottom: -8px;
            }
            .leaderboardBestPositionNumber {
              font-weight: 900;
              font-size: 24.2703px;
              line-height: 33px;
              color: #a20c0c;
              @include respond(mobileWide) {
                font-size: 14px;
                line-height: 20px;
              }
            }
          }
        }
        .leaderboardBestPositionPlayerName {
          font-weight: 700;
          font-size: 24.2703px;
          line-height: 40px;
          color: #a20c0c;
          padding-top: 20px;
          a {
            text-decoration: none;
            color: inherit;
          }
          @include respond(mobileWide) {
            font-size: 12px;
            line-height: 20px;
          }
        }
        .leaderboardBestPositionPlayerScore {
          font-size: 19.8575px;
          line-height: 32px;
          letter-spacing: 0.511161px;
          color: #006baa;
          @include respond(mobileWide) {
            font-size: 12px;
            line-height: 20px;
          }
        }
      }
    }
  }
  .leaderboardBlock {
    position: relative;
    width: 100%;
    overflow: auto;
    .leaderboardTable {
      width: 100%;
      border-collapse: separate;
      border-spacing: 0 10px;
      thead {
        @include respond(mobileWide) {
          display: none;
        }
        tr {
          th {
            font-weight: 800;
            font-size: 15.8924px;
            line-height: 22px;
            letter-spacing: 0.529746px;
            color: #7aa1b4;
            top: 0px;
            padding: 6px 0;
            z-index: 1;
            &:nth-child(1) {
              width: 5%;
              @include respond(mobileWide) {
                width: 10%;
              }
            }
            &:nth-child(2) {
              text-align: left;
              width: 65%;
              @include respond(mobileWide) {
                width: 60%;
              }
            }
            &:nth-child(3) {
              width: 15%;
              text-align: left;
            }
            &:nth-child(4) {
              width: 15%;
              text-align: left;
            }
          }
        }
      }
      tbody {
        tr {
          background-color: #fff;
          border: 1px solid transparent;
          border-radius: 15px;
          box-shadow: 0px 2.99856px 2.99856px rgba(0, 0, 0, 0.25);
          td {
            border-width: 1px;
            &:nth-child(1) {
              padding: 10px 8px;
              text-align: center;
              background: #bcced9;
              color: #315468;
              border-top-left-radius: 12px;
              border-bottom-left-radius: 12px;
              border-color: inherit;
              border-style: solid;
              border-width: 1px;
              width: 5%;
              @include respond(mobileWide) {
                width: 10%;
              }
              &.firstPosition {
                background: #ffba35;
                color: #c15100;
              }
              &.secondPosition {
                background: #aa66ff;
                color: #6623ba;
              }
              &.thirdPosition {
                background: #ff6666;
                color: #c21212;
              }
            }
            &:nth-child(2) {
              display: flex;
              align-items: center;
              padding: 10px 8px;
              font-weight: 800;
              font-size: 16px;
              line-height: 22px;
              color: #006baa;
              border-color: inherit;
              border-style: solid;
              border-right-width: 0;
              border-left-width: 0;
              width: 65%;
              @media screen and (max-width: 1520px) {
                font-size: 14px;
              }
              @include respond(mobileWide) {
                width: 60%;
              }
              img {
                width: 36px;
                margin-right: 5px;
                @media screen and (max-width: 1520px) {
                  width: 30px;
                }
              }
            }
            &:nth-child(3) {
              font-weight: 800;
              font-size: 16px;
              line-height: 22px;
              text-align: center;
              color: #7aa1b4;
              border-color: inherit;
              border-style: solid;
              border-left: 1px solid #bcced9;
              border-right: 1px solid #bcced9;
              width: 15%;
              @media screen and (max-width: 1520px) {
                font-size: 14px;
              }
            }
            &:nth-child(4) {
              text-align: center;
              border-color: inherit;
              border-style: solid;
              border-width: 1px;
              border-left-width: 0;
              border-top-right-radius: 12px;
              border-bottom-right-radius: 12px;
              width: 15%;
              img {
                width: 18.5px;
                margin: 0 4px;
                @media screen and (max-width: 1520px) {
                  margin: 0 3px;
                }
              }
            }
            a {
              text-decoration: none;
              color: inherit;
            }
          }
        }
      }
      .leaderboardDesktopSocialMedia {
        display: block;
        @include respond(mobileWide) {
          display: none;
        }
      }
      .leaderboardMobileSocialMedia {
        position: relative;
        display: none;
        @include respond(mobileWide) {
          display: block;
        }
        .socialMediaMobileBlock {
          position: absolute;
          background: #ffffff;
          box-shadow: 0px 2.99856px 2.99856px rgba(0, 0, 0, 0.15);
          border-radius: 11px;
          width: 100%;
          min-width: 150px;
          padding: 10px;
          left: 50%;
          transform: translateX(-80%);
          top: 45px;
          z-index: 1;
          border: 1px solid #c3d5df;
          &.dBlock {
            display: block !important;
          }
          .triangle {
            // using clip-path
            display: block;
            height: 20px;
            width: 20px;
            background-color: inherit;
            border: inherit;
            position: absolute;
            top: -10px;
            right: 20px;
            clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
            transform: rotate(135deg);
            border-radius: 0 0 0 0.25em;
          }
          .socialMediaItemMobile {
            display: flex;
            align-items: center;
            font-weight: 800;
            font-size: 14px;
            line-height: 17px;
            color: #006baa;
            padding: 12px 0;
            border-bottom: 1px solid #c3d5df;
            a {
              text-decoration: none;
              color: #006baa;
            }
            img {
              margin-right: 8px;
            }
            &:last-child {
              border-bottom: 0px;
            }
          }
        }
      }
    }
  }
  .ant-tabs-nav-list {
    background: #fff;
    border-radius: 15px;
    border-top-left-radius: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    .ant-tabs-tab {
      display: flex;
      padding: 22px 60px;
      margin: 0;
      @media screen and (max-width: 1120px) {
        padding: 15px 40px;
      }
      @include respond(mobileWide) {
        padding: 15px 20px;
      }
      @include respond(mobile) {
        padding: 15px 8px;
      }
      &:nth-child(1) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 15px;
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }
      &:nth-child(2) {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }
      &:nth-child(3) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }
      &:nth-child(4) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }
      .ant-tabs-tab-btn {
        color: #006baa;
        font-weight: 900;
        font-size: 14px;
        line-height: 30px;
        text-align: center;
        @media screen and (max-width: 1120px) {
          font-size: 18px;
          line-height: 27px;
        }
        @include respond(mobileWide) {
          font-size: 14px;
          line-height: 20px;
        }
      }
      &.ant-tabs-tab-active {
        background-color: #006baa;
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px;
        .ant-tabs-tab-btn {
          color: #ffffff;
        }
      }
    }
  }
  .ant-tabs-nav {
    margin-bottom: 20px;
  }
  .ant-tabs-nav::before {
    border-bottom: 0 !important;
  }
  .ant-tabs-ink-bar {
    background: none;
  }
}

.videoSwiperMainScope {
  position: relative;
  margin-bottom: 70px;
  .videoSwiperScope {
    .videoSwiperItem {
      position: relative;
      width: 100%;
      border-radius: 20px;
      display: flex;
      overflow: hidden;
      justify-content: space-between;
      background: #02507d;
      @media screen and (max-width: 640px) {
        flex-direction: column-reverse;
      }
      .videoSwiperItemImg {
        position: relative;
        display: flex;
        height: 480px;
        width: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        @media screen and (max-width: 1920px) {
          height: 405px;
        }
        @media screen and (max-width: 1730px) {
          height: 330px;
        }
        @media screen and (max-width: 1520px) {
          height: 280px;
        }
        @media screen and (max-width: 1335px) {
          height: 280px;
        }
        @media screen and (max-width: 1120px) {
          height: 310px;
        }
        @media screen and (max-width: 1080px) {
          height: 275px;
        }
        @media screen and (max-width: 768px) {
          height: 210px;
        }
        @media screen and (max-width: 640px) {
          height: 335px;
        }
        @media screen and (max-width: 500px) {
          height: 220px;
        }
        img {
          display: block;
          width: 100%;
        }
        .videoSwiperItemImgGradient {
          position: absolute;
          left: -1px;
          background: linear-gradient(
            91.03deg,
            #02507d 6%,
            rgba(2, 80, 125, 0) 94%
          );
          height: 100%;
          width: 200px;
          @media screen and (max-width: 640px) {
            top: none;
            bottom: -1px;
            left: 0;
            background: linear-gradient(
              360deg,
              #02507d 6%,
              rgba(2, 80, 125, 0) 94%
            );
            height: 250px;
            width: 100%;
          }
        }
      }

      .videoSwiperItemLeftBlock {
        display: flex;
        width: 100%;
        max-width: 360px;
        height: auto;
        border-radius: 20px;
        @media screen and (max-width: 640px) {
          justify-content: center;
          max-width: 100%;
        }
        .videoSwiperItemInfoBlock {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          padding: 0 30px;
          @media screen and (max-width: 640px) {
            position: relative;
            top: 0;
            transform: none;
          }
          .videoSwiperItemInfo {
            width: 293px;
            font-weight: 800;
            font-size: 30px;
            line-height: 44px;
            letter-spacing: 0.529746px;
            color: #ffffff;
            @media screen and (max-width: 1730px) {
              font-size: 26px;
              line-height: 38px;
            }
            @media screen and (max-width: 1520px) {
              font-size: 20px;
              line-height: 32px;
            }
            @include respond(mobileWide) {
              width: 230px;
            }
            @include respond(mobileMedium) {
              width: 100%;
            }
            @include respond(mobile) {
              font-size: 16px;
              line-height: 28px;
            }
          }
          .videoSwiperItemButton {
            display: flex;
            justify-content: center;
            align-items: center;
            color: #006baa;
            font-weight: 800;
            font-size: 22px;
            line-height: 100%;
            text-align: center;
            padding: 11px 22px;
            background: #fff;
            margin-top: 70px;
            border-radius: 10px;
            border-top-left-radius: 0;
            transition: all 0.34s ease;
            box-shadow: 0 3px 3px 0 rgb(0 0 0 / 25%);
            text-decoration: none;
            @media screen and (max-width: 1730px) {
              margin-top: 40px;
              font-size: 20px;
              padding: 8px 18px;
            }
            @media screen and (max-width: 1520px) {
              margin-top: 30px;
              font-size: 18px;
              padding: 8px 16px;
            }
            @include respond(mobileMedium) {
              margin-top: 20px;
              margin-bottom: 30px;
            }
            @include respond(mobile) {
              font-size: 16px;
              padding: 6px 14px;
            }
            img {
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
  .videoSwiperNavigationPaginationBlock {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    padding-top: 50px;
    bottom: -50px;
    display: flex;
    align-items: center;
    z-index: 3;
    .previousButton {
      margin-right: 5px;
    }
    .nextButton {
      margin-left: 5px;
    }
    img {
      display: block;
      width: 32px;
      height: 32px;
      cursor: pointer;
    }
  }
}

.videoGalleryScope {
  display: flex;
  flex-wrap: wrap;
  margin-top: 50px;
  @include respond(mobileWide) {
    margin-top: 30px;
  }
  .videoGalleryMainTitle {
    position: relative;
    float: left;
    width: 100%;
    padding-bottom: 10px;
    font-weight: 900;
    font-size: 25.8251px;
    line-height: 35px;
    color: #006baa;
    @include respond(mobileWide) {
      font-size: 14px;
      line-height: 16px;
    }
  }
  .videoGallerySectionItem {
    width: 24.4%;
    margin-right: 0.8%;
    margin-bottom: 23px;
    &:nth-child(4n + 1) {
      margin-right: 0;
    }
    @include respond(tablet) {
      width: 32.6%;
      margin-right: 1%;
      &:nth-child(3n + 1) {
        margin-right: 0;
      }
      &:nth-child(4n + 1) {
        margin-right: 1%;
      }
    }
    @include respond(mobileMedium) {
      width: 49%;
      margin-right: 2%;
      &:nth-child(2n + 1) {
        margin-right: 0% !important;
      }
      &:nth-child(3n + 1) {
        margin-right: 2%;
      }
    }
    .videoGallerySectionItemThumbnail {
      position: relative;
      display: flex;
      border-radius: 10px;
      overflow: hidden;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      margin: 3px auto 0;
      position: relative;
      height: 250px;
      @media screen and (max-width: 1920px) {
        height: 220px;
      }
      @media screen and (max-width: 1730px) {
        height: 195px;
      }
      @media screen and (max-width: 1520px) {
        height: 170px;
      }
      @media screen and (max-width: 1335px) {
        height: 140px;
      }
      @media screen and (max-width: 1120px) {
        height: 120px;
      }
      @media screen and (max-width: 1080px) {
        height: 155px;
      }
      @media screen and (max-width: 768px) {
        height: 110px;
      }
      @media screen and (max-width: 640px) {
        height: 170px;
      }
      @media screen and (max-width: 500px) {
        height: 130px;
      }
      a {
        display: flex;
      }
      &:hover {
        .videoGallerySectionItemThumbnailHover {
          display: block;
        }
      }
      img {
        width: 100%;
      }
      .videoGallerySectionItemThumbnailHover {
        position: absolute;
        display: none;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 0;
        background: url(play.png) no-repeat center center / 78px 78px;
        background-size: cover cover;
        background-color: #0000006e;
        background-position: center center;
        border-radius: 10px;
        background-repeat: no-repeat;
        @include respond(tablet) {
          background: url(play.png) no-repeat center center / 48px 48px;
          background-color: #0000006e;
        }
      }
    }
    .videoGallerySectionItemThumbnailName {
      a {
        display: flex;
        padding-top: 8px;
        text-decoration: none;
        font-weight: 800;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0.529746px;
        color: #006baa;
        @include respond(mobileWide) {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }
}

.videoIframe {
  .videoIframeVideoName {
    font-weight: 800;
    font-size: 25px;
    line-height: 34px;
    color: #006baa;
    margin-top: 20px;
  }
}

.video-js {
  width: 100%;
  height: 25.625vw; /* 90*9/16 */
  border-radius: 15px;
  @media (max-width: 1920px) {
    width: 100%;
    height: 30.625vw; /* 90*9/16 */
  }
  @media (max-width: 768px) {
    width: 100%;
    height: 50.625vw; /* 90*9/16 */
  }
  @media (max-width: 450px) {
    width: 100%;
    height: 60.625vw; /* 90*9/16 */
  }
  .vjs-big-play-button {
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    background-color: rgb(255 255 255) !important;
    color: #000000a1 !important;
    line-height: 1.9em !important;
    height: 2em !important;
    width: 2em !important;
    border-radius: 1em !important;
  }
  .vjs-control-bar {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }
}

.videoNameTitle {
  font-weight: 800;
  font-size: 25px;
  line-height: 34px;
  color: #006baa;
  padding-top: 15px;
  @include respond(mobileWide) {
    font-size: 14px;
    line-height: 20px;
  }
}

.skipAd {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 9999;
  background: #000;
  height: 70px;
  text-align: right;
  width: 100%;
  pointer-events: none;
  display: none;
}

.skipAd span {
  font-weight: bold;
  color: white;
  font-size: 20px;
  line-height: 50px;
  display: block;
  margin: 10px;
  cursor: pointer;
}

.skipAdFooter {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 9999;
  background: #000;
  height: 70px;
  text-align: right;
  width: 100%;
  pointer-events: none;
  display: none;
}

@keyframes shine {
  to {
    opacity: 1;
    right: 210%;
  }
}

.col-0:after {
  content: "";
  position: absolute;
  top: -30%;
  right: -200%;
  width: 50%;
  height: 200%;
  opacity: 0;
  transform: rotate(30deg);
  background: rgba(255, 255, 255, 0.13);
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.13) 0%,
    rgba(255, 255, 255, 0.13) 77%,
    rgba(255, 255, 255, 0.5) 92%,
    rgba(255, 255, 255, 0) 100%
  );
  animation: shine 3s 1s;
}

.col-1:after {
  content: "";
  position: absolute;
  top: -30%;
  right: -200%;
  width: 50%;
  height: 200%;
  opacity: 0;
  transform: rotate(30deg);
  background: rgba(255, 255, 255, 0.13);
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.13) 0%,
    rgba(255, 255, 255, 0.13) 77%,
    rgba(255, 255, 255, 0.5) 92%,
    rgba(255, 255, 255, 0) 100%
  );
  animation: shine 3s 1s;
}

.col-3:after {
  content: "";
  position: absolute;
  top: -30%;
  right: -200%;
  width: 50%;
  height: 200%;
  opacity: 0;
  transform: rotate(30deg);
  background: rgba(255, 255, 255, 0.13);
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.13) 0%,
    rgba(255, 255, 255, 0.13) 77%,
    rgba(255, 255, 255, 0.5) 92%,
    rgba(255, 255, 255, 0) 100%
  );
  animation: shine 3s 1s;
}
//highlights section
.highlightsSection {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  margin-bottom: 20px;
  margin-top: -10px;
}

.highlightItem {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.highlightItem svg {
  font-size: 40px;
  color: #f58bb2;
  margin-right: 10px;
}

.highlightItem span {
  font-size: 16px;
  color: #00459c;
}
.introSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  margin-left: 20px;
}

.introLogo {
  width: 70px;
  height: auto;
  margin-bottom: 5px;
}

.introTitle {
  font-size: 22px;
  color: #00459c;
  margin-left: 20px;
  margin-right: 15px;
}

@media (max-width: $screen-desktopWide) {
  .introSection {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
    margin-right: 30px;
    padding-right: 30px;
  }
  .introLogo {
    width: 80px;
  }

  .introTitle {
    font-size: 28px;
    max-width: 150px;
    white-space: wrap;
    overflow: visible;
  }
  .highlightItem {
    display: flex;
    align-items: center;
    margin-right: 20px;
  }
  .highlightItem span {
    font-size: 16px;
    color: #00459c;
    display: inline-block;
    max-width: 150px;
    white-space: wrap;
    overflow: visible;
  }
}

@media (max-width: $screen-smallDesktop) {
  .introLogo {
    width: 70px;
  }

  .introTitle {
    font-size: 24px;
  }
}

@media (max-width: $screen-tablet) {
  .introSection {
    flex-direction: column;
  }

  .introLogo {
    width: 60px;
  }

  .introTitle {
    font-size: 20px;
    margin-left: 0;
  }

  .highlightItem svg {
    font-size: 30px;
  }

  .highlightItem span {
    font-size: 14px;
  }
}
@media (max-width: $screen-mobileWide) {
  .highlightItem {
    flex-direction: row;
    margin: 5px 10px;
    margin-right: 10px;
  }

  .highlightItem svg {
    font-size: 30px;
  }

  .highlightItem span {
    font-size: 16px;
  }
  .introSection {
    display: none;
  }
}

@media (max-width: $screen-mobileMedium) {
  .highlightItem {
    flex-direction: column;
    text-align: center;
  }

  .highlightItem svg {
    margin-bottom: 5px;
  }
}

@media (max-width: $screen-mobileSmall) {
  .highlightItem svg {
    font-size: 22px;
  }

  .highlightItem span {
    font-size: 10px;
  }
}
